<template>
    <div v-if="!isTwoFA" class="input-container">
        <div>
            <label class="tf-label" :for="id">
                {{ label }}                  
                <strong v-if="required">
                    <sup class="fhb-red">*</sup>
                </strong>
            </label>
            <input
                type="text"
                :value="modelValue" 
                :id="id"
                :class="missing || invalid ? 'll-textfield-error' : 'll-textfield '"
                :aria-describedby="ariaDescribe"
                :name="name"
                :data-test="test"
                :aria-required="required"
                @blur="handleBlur"
                :disabled="disable"
                :required="required"
            />
            <div class="error-container" role="alert" :id="ariaDescribe">
                <div v-if="missing || invalid" class="error">
                    <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="account number error"/>
                    <div v-if="missing" id="empty">
                    Please enter a value
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- for instances where there are a bunch of selectable options shown (in html) -->
    <div v-else class="twofa-container">
        <div v-html="label" />
        <input
            type="text"
            :value="modelValue" 
            :id="id"
            :class="missing || invalid ? 'll-textfield-error' : 'll-textfield '"
            :aria-describedby="ariaDescribe"
            :name="name"
            :data-test="test"
            :aria-required="required"
            @blur="handleBlur"
            :disabled="disable"
            :required="required"
        />
        <div class="error-container-twofa" role="alert" :id="ariaDescribe">
            <div v-if="missing || invalid" class="error">
                <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="account number error"/>
                <div v-if="missing" id="empty">
                Please enter a value
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    name: 'RealTimeInput',
    emits: ['blur', 'update:modelValue'],
    props: {
        modelValue: String,
        label: String,
        id: String,
        missing: Boolean, 
        invalid: Boolean,
        required: Boolean,
        ariaDescribe: String,
        name: String,
        test: String,
        disable: Boolean,
    }, 
    data () {
        return {
            element: null,
            formatted: "",
            raw: "",
            isTwoFA: false,
        }
    },
    created() {
        // check to see if label has any html elements to determine what to display
        const checkHTML = /<\/?[a-z][\s\S]*>/i
        this.isTwoFA = checkHTML.test(this.label)
    },
    watch: {
        label(v) {
            const checkHTML = /<\/?[a-z][\s\S]*>/i
            this.isTwoFA = checkHTML.test(v)
        }
    },
    computed: {
        cmdKey: {
            get () { 
                if (this.getBrowser() == "firefox") {
                    return 224
                }
                return 91
            }
        }
    },
    methods: {
        getBrowser() {
            let agent = navigator.userAgent
            if (agent.match("/chrome|chromium|crios/i")) return "chrome"
            if (agent.match("/firefox|fxios/i")) return "firefox"
            if (agent.match("/safari/i")) return "safari"
            if (agent.match("/edg/i")) return "edge"
        },
        handleBlur(e){
            this.$emit('update:modelValue', e.target.value.trim())
            this.$emit('blur')
        }
    }
})
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.input-container {
    div {
     max-width: 550px;
     width: 100%;
    }
    display: flex;
    justify-content: center;
}
.error-container {
  height: 30px;
}
.error-container-twofa {
  height: 30px;
  max-width: 160px;
  margin-right: auto;
  margin-left: auto;
}
.error {
  color: $red;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  display: flex;

  img {
    width: 14px;
    height: 14px;   
  }
}
.error-twofa {
  color: $red;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  >* {
    display: inline-block;
  }
  img {
    width: 14px;
    height: 14px;   
    vertical-align: middle;
    margin: auto 0px;
    margin-right: 5px;
  }
}
.ll-texfield {
    max-width: 1000px;
}
.twofa-container {
    max-width: 550px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    input {
        max-width: 100px;
        margin-right: auto;
        margin-left: auto;
        font-size: 30px;
        text-align: center;
    }
}

</style>

export default ({
    namespaced: true,

    state: {
        // initial page
        product:'',
        // login
        user: '',

        queryParams: '',

        // page 1
        customerType: '',
        funding_id: '',
        account_id: [],
        customer_data: {},
        customer_accounts: [],
        manually_funded_accounts: [],
        transfer_from: '',
        total: 0,
        hasLinkedExternalAccounts: false,
        hasValidInternal: false,

        // internal flow
        internal_accounts: [],
        selectedAccount: '',
        internal_account_transfers: null,

        // external flow 
        accounts: [],
        extSelectedAccount: '',

        // external page 3
        account_type: '',
        routing_num: '',
        account_num: '',
        saveAccount: false,
        account_nickname: '',
        readAgreement: false,
        
        loginToAccount: '',
        verifyAmounts: '',
        verifyMethod: '',

        bankSelected: '',
        selectedBankAccount: '',
        cd_found: false,

        last_name: '',
        first_name: '',
        dob: '',
        ssn: '',

        mfa_phones: null,
        mfa_emails: null,
        mfa_info: '',
        mfa_method: '',
        has_internal: false,
        is_verified: false,

        realtime_resp: {},
        realtime_verify_resp: {},
        realtime_params: [],

        jwt_token: '',

        real_time_verification: false,
        oauth_verification: false, // Takes precedence over real_time_verification
        oauth_url: '',
        oauth_method: '', 
        mfaErrCounter: 0,
        resend_info: '',
        
        // enrollment
        username: '',
        enrollment_sso: '',
    },
    getters: {
        // inital page
        getProduct: (state) => state.product,
        // login
        getUser: (state) => state.user,

        // page 1
        getFundingID: (state) => state.funding_id,
        getAccountID: (state) => state.account_id,
        getCustomerType: (state) => state.customerType,
        getTransferFrom: (state) => state.transfer_from,
        getCustomerData: (state) => state.customer_data,
        getTotal: (state) => state.total,

        // internal flow
        getInternalAccounts: (state) => state.internal_accounts,
        getSelectedAccount: (state) => state.selectedAccount,
        getInternalAccountTransfers: (state) => state.internal_account_transfers,

        getAccounts: (state) => state.accounts,
        getExtSelectedAccount: (state) => state.extSelectedAccount,
        // page 3
        getAccountType: (state) => state.account_type,
        getRoutingNum: (state) => state.routing_num,
        getAccountNum: (state) => state.account_num,
        getAccountNickname: (state) => state.account_nickname,
        getSaveAccount: (state) => state.saveAccount,
        getReadAgreement: (state) => state.readAgreement,

        getLoginAccount: (state) => state.loginToAccount,
        verifyAmounts: (state) => state.verifyAmounts,
        getVerifyMethod: (state) => state.verifyMethod,

        getBankSelected: (state) => state.bankSelected,

        getSelectedBankAccount: (state) => state.selectedBankAccount,
        getCDFound: (state) => state.cd_found,

        getCustomerAccounts: (state) => state.customer_accounts,
        getManuallyFundedAccounts: (state) => state.manually_funded_accounts,

        getLastName: (state) => state.last_name,
        getFirstName: (state) => state.first_name,
        getDOB: (state) => state.dob,
        getSSN: (state) => state.ssn,

        getMFAPhones: (state) => state.mfa_phones,
        getMFAEmails: (state) => state.mfa_emails,
        getMFAInfo: (state) => state.mfa_info,
        getMFAMethod: (state) => state.mfa_method,
        
        getJwtToken: (state) => state.jwt_token,

        getRealtimeVerification: (state) => state.real_time_verification,
        getHasLinkedExternalAccounts: (state) => state.hasLinkedExternalAccounts,
        getHasInternal: (state) => state.has_internal,
        getHasValidInternal: (state) => state.hasValidInternal,
        getIsVerified: (state) => state.is_verified,

        getRealtimeResp: (state) => state.realtime_resp,
        getRealtimeVerifyResp: (state) => state.realtime_verify_resp,
        getRealtimeParams: (state) => state.realtime_params,
        getMfaErrCounter: (state) => state.mfaErrCounter,
        getResendInfo: (state) => state.resend_info,
        getOAuthVerification: (state) => state.oauth_verification,
        getOAuthURL: (state) => state.oauth_url,
        getOAuthMethod: (state) => state.oauth_method,

        // enrollment
        getUsername: (state) => state.username,
        getEnrollmentSSO: (state) => state.enrollment_sso,
        getQueryParams: (state) => state.queryParams
    },
    mutations: {
        setHasLinkedExternalAccounts(state, value) {
            state.hasLinkedExternalAccounts = value;
        },
        //  initial page
        setProduct(state, value) {
            state.product = value;
        },

        // login
        setUser(state, value) {
            state.user = value;
        },

        setFundingID(state, value) {
            state.funding_id = value;
        },
        setAccountID(state, value) {
            state.account_id = value;
        },
        setCustomerType(state, value) {
            state.customerType = value;
        },
        setTransferFrom(state, value) {
            state.transfer_from = value;
        },
        setCustomerData(state, value) {
            state.customer_data = value;
        },
        setTotal(state, value) {
            state.total = value;
        },

        // internal flow
        setInternalAccounts(state, value) {
            state.internal_accounts = value;
        },
        setSelectedAccount(state, value) {
            state.selectedAccount = value;
        },

        setAccounts(state, value) {
            state.accounts = value;
        },
        setExtSelectedAccount(state, value) {
            state.extSelectedAccount = value;
        },
        setInternalAccountTransfers(state, value) {
            state.internal_account_transfers = value;
        },
      
        // page 3
        setAccountType(state, value) {
            state.account_type = value;
        },
        setRoutingNum(state, value) {
            state.routing_num= value;
        },
        setAccountNum(state, value) {
            state.account_num = value;
        },
        setAccountNickname(state, value) {
            state.account_nickname = value;
        },
        setSaveAccount(state, value) {
            state.saveAccount = value;
        },
        setReadAgreement(state, value) {
            state.readAgreement = value;
        },
        setLoginAccount(state, value) {
            state.loginToAccount = value;
        },
        setVerifyAmounts(state, value) {
            state.verifyAmounts = value;
        },
        setVerifyMethod(state, value) {
            state.verifyMethod= value;
        },

        setBankSelected(state, value) {
            state.bankSelected = value;
        },
        setSelectedBankAccount(state, value) {
            state.selectedBankAccount = value;
        },
        setCDFound(state, value) {
            state.cd_found = value;
        },
        setCustomerAccounts(state, value) {
            state.customer_accounts = value;
        },
        setManuallyFundedAccounts(state, value) {
            state.manually_funded_accounts = value;
        },
        setMFAPhones(state, value) {
            state.mfa_phones = value;
        },
        setMFAEmails(state, value) {
            state.mfa_emails = value;
        },
        setMFAInfo(state, value) {
            state.mfa_info = value;
        },
        setMFAMethod(state, value) {
            state.mfa_method = value;
        },
        setJwtToken(state, value) {
            state.jwt_token = value;
        },
        setLastName(state, value) {
            state.last_name = value;
        },
        setFirstName(state, value) {
            state.first_name = value;
        },
        setDOB(state, value) {
            state.dob = value;
        },
        setSSN(state, value) {
            state.ssn = value;
        },
        setRealTimeVerification(state, value) {
            state.real_time_verification = value;
        },
        setHasInternal(state, value) {
            state.has_internal = value;
        },
        setHasValidInternal(state, value) {
            state.hasValidInternal = value;
        },
        setIsVerified(state, value) {
            state.is_verified = value;
        },
        setRealtimeResp(state, value) {
            state.realtime_resp = value;
        },
        setRealtimeVerifyResp(state, value) {
            state.realtime_verify_resp = value;
        },
        setRealtimeParams(state, value) {
            state.realtime_params = value;
        },
        setMfaErrCounter(state, value) {
            state.mfaErrCounter = value
        },
        setResendInfo(state, value) {
            state.resend_info = value
        },
        setOAuthVerification(state, value) {
            state.oauth_verification = value
        },
        setOAuthURL(state, value) {
            state.oauth_url = value
        },
        setOAuthMethod(state, value) {
            state.oauth_method = value
        },
        // enrollment
        setUsername(state, value) {
            state.username = value;
        },
        setEnrollmentSSO(state, value) {
            state.enrollment_sso = value;
        },
        setQueryParams(state, value) {
            if (value.charAt(0) == '?') {
                state.queryParams = value.splice(1)
            } else {
            state.queryParams = value;
            }
        },
    },
    actions: {
        resetAll({commit}) {
            commit('setHasLinkedExternalAccounts', false)
            commit('setProduct', '')
            commit('setFundingID', '')
            commit('setAccountID', [])
            commit('setCustomerType', '')
            commit('setTransferFrom', '')
            commit('setTotal', 0)
            commit('setAccounts', [])
            commit('setInternalAccounts', [])
            commit('setSelectedAccount', '')
            commit('setExtSelectedAccount', '')
            commit('setCustomerData', [])
            commit('setAccountType', '')
            commit('setRoutingNum', '')
            commit('setAccountNum', '')
            commit('setAccountNickname', '')
            commit('setSaveAccount', '')
            commit('setReadAgreement', '')
            commit('setVerifyAmounts', '')
            commit('setLoginAccount', '')
            commit('setVerifyMethod', '')
            commit('setBankSelected', '')
            commit('setSelectedBankAccount', '')
            commit('setInternalAccountTransfers', '')

            commit('setLastName', '')
            commit('setDOB', '')
            commit('setSSN', '')

            commit('setMFAPhones', null)
            commit('setMFAEmails', null)
            commit('setMFAInfo', '')
            commit('setMFAMethod', '')
            commit('setJwtToken', '')
            commit('setCDFound', false)
            commit('setUser', '')
            commit('setCustomerAccounts', [])
            commit('setManuallyFundedAccounts', [])
            commit('setRealTimeVerification', [])
            commit('setHasInternal', false)
            commit('setHasValidInternal', false)
            commit('setIsVerified', false)
            commit('setFirstName', '')
            commit('setRealtimeResp', {})
            commit('setRealtimeVerifyResp', {})
            commit('setRealtimeParams', [])
            commit('setMfaErrCounter', 0)
            commit('setResendInfo', '')
            commit('setOAuthVerification', false)
            commit('setOAuthURL', '')
            commit('setOAuthMethod', '')
            commit('setUsername', '')
            commit('setEnrollmentSSO', '')
            commit('setQueryParams', '')
        },
        setHasLinkedExternalAccounts({ commit }, value) {
            commit('setHasLinkedExternalAccounts', value)
        },
        // initial page
        setProduct({ commit }, value) {
            commit('setProduct', value)
        },

        // login
        setUser({ commit }, value) {
            commit('setUser', value)
        },

        // page 1
        setFundingID({ commit }, value) {
            commit('setFundingID', value)
        },
        setAccountID({ commit }, value) {
            commit('setAccountID', value)
        },
        setCustomerType({ commit }, value) {
            commit('setCustomerType', value)
        },
        setTransferFrom({ commit }, value) {
            commit('setTransferFrom', value)
        },
        setCustomerData({ commit }, value) {
            commit('setCustomerData', value)
        },
        setTotal({ commit }, value) {
            commit('setTotal', value)
        },
        setAccounts({ commit }, value) {
            commit('setAccounts', value)
        },

        // internal flow
        setInternalAccounts({ commit }, value) {
            commit('setInternalAccounts', value)
        },
        setSelectedAccount({ commit }, value) {
            commit('setSelectedAccount', value)
        },
        setInternalAccountTransfers({ commit }, value) {
            commit('setInternalAccountTransfers', value)
        },
        
        setExtSelectedAccount({ commit }, value) {
            commit('setExtSelectedAccount', value)
        },

        // page 3
        setAccountType({ commit }, value) {
            commit('setAccountType', value)
        },
        setRoutingNum({ commit }, value) {
            commit('setRoutingNum', value)
        },
        setAccountNum({ commit }, value) {
            commit('setAccountNum', value)
        },
        setAccountNickname({ commit }, value) {
            commit('setAccountNickname', value)
        },
        setSaveAccount({ commit }, value) {
            commit('setSaveAccount', value)
        },
        setReadAgreement({ commit }, value) {
            commit('setReadAgreement', value)
        },
        setVerifyAmounts({ commit }, value) {
            commit('setVerifyAmounts', value)
        },
        setLoginAccount({ commit }, value) {
            commit('setLoginAccount', value)
        },
        setVerifyMethod({ commit }, value) {
            commit('setVerifyMethod', value)
        },
      
        setBankSelected({ commit }, value) {
            commit('setBankSelected', value)
        },
        setSelectedBankAccount({ commit }, value) {
            commit('setSelectedBankAccount', value)
        },
        setCDFound({ commit }, value) {
            commit('setCDFound', value)
        },
        setCustomerAccounts({ commit }, value) {
            commit('setCustomerAccounts', value)
        },
        setManuallyFundedAccounts({ commit }, value) {
            commit('setManuallyFundedAccounts', value)
        },
        setLastName({ commit }, value) {
            commit('setLastName', value)
        },
        setFirstName({ commit }, value) {
            commit('setFirstName', value)
        },
        setDOB({ commit }, value) {
            commit('setDOB', value)
        },
        setSSN({ commit }, value) {
            commit('setSSN', value)
        },
        setMFAPhones({ commit }, value) {
            commit('setMFAPhones', value)
        },
        setMFAEmails({ commit }, value) {
            commit('setMFAEmails', value)
        },
        setMFAInfo({ commit }, value) {
            commit('setMFAInfo', value)
        },
        setMFAMethod({ commit }, value) {
            commit('setMFAMethod', value)
        },
        setRealTimeVerification({ commit }, value) {
            commit('setRealTimeVerification', value)
        },
        setJwtToken({ commit }, value) {
            commit('setJwtToken', value)
        },
        setIsVerified({ commit }, value) {
            commit('setIsVerified', value)
        },
        setHasInternal({ commit }, value) {
            commit('setHasInternal', value)
        },
        setHasValidInternal({ commit }, value) {
            commit('setHasValidInternal', value)
        },
        setRealtimeResp({ commit }, value) {
            commit('setRealtimeResp', value)
        },
        setRealtimeVerifyResp({ commit }, value) {
            commit('setRealtimeVerifyResp', value)
        },
        setRealtimeParams({ commit }, value) {
            commit('setRealtimeParams', value)
        },
        setMfaErrCounter ({ commit }, value) {
            commit('setMfaErrCounter', value)
        },
        setResendInfo ({ commit }, value) {
            commit('setResendInfo', value)
        },
        setOAuthVerification ({ commit }, value) {
            commit('setOAuthVerification', value)
        },
        setOAuthURL ({ commit }, value) {
            commit('setOAuthURL', value)
        },
        setOAuthMethod ({ commit }, value) {
            commit('setOAuthMethod', value)
        },
        // enrollment
        setUsername({ commit }, value) {
            commit('setUsername', value)
        },
        setEnrollmentSSO({ commit }, value) {
            commit('setEnrollmentSSO', value)
        },
        setQueryParams({ commit }, value) {
            
            commit('setQueryParams', value)
        },
    },

  })
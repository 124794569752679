<!-- Path: /open-account/ -->
<template>
  <div class="page">
    <SplashBackground/>
    <div class="center">
      <FormContainer header="Open an Account" :subheader="!product ? null : `${product_map[product]}`">
        <!-- use slot -->
        <template #form-content>
         <div class="instruction-container">
          <div>
            To apply online you must
          </div>
          <div></div>
          <ul>
            <li>
              Be a U.S Citizen residing in Hawaii, Guam, or Saipan
            </li>
            <li>
              Be at least 18 years old
            </li>
            <li>
              Have a Social Security number
            </li>
            <li>
              <div>
                Have a Government Issued ID (Driver's License or State ID)
              </div>
            </li>
          </ul>          
         </div>
         <div class="button-container">
          <button type="button" class="cust-button" @click="newCustomer">Continue</button>
         </div>
         <div class="link-container">
          <strong>Existing Customer? </strong><a :href="existingCustomer()">Login to FHB Online & Mobile Banking</a> to apply quickly and easily
         </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import SplashBackground from '@/components/Splash.vue'
import constants from '@/app/constants'
import env from '@/app/env'

export default {
  name: 'OpenAccount',
  components: {
    FormContainer,
    SplashBackground
  },
  data() {
    return {
      product_map: constants.oao_fhb_product_params,
      formattedProduct: ''
    }
  },
    methods: {
      ...mapActions('fundnow', ['setProduct', 'setQueryParams']),
    newCustomer() {
      // remove old product param and add on reformatted product param
      var redirect = env.q2OpenAccountURL() + this.newCustomerQueryParams
      window.location.href = redirect
    },
    existingCustomer() {
      let redirect = "/existing-login" + this.queryParams
      return redirect
    }
  },
  computed: {
    ...mapGetters('fundnow', [ 'getProduct', 'getQueryParams']),
    product: {
      get() { return this.getProduct },
      set(v){ this.setProduct(v)}
    },
    queryParams: {
      get() { 
        const params =  this.getQueryParams
        if (params.charAt(0) == "?") return params
        else return "?" + this.getQueryParams
      },
      set(v){this.setQueryParams(v)}
    },
    newCustomerQueryParams: {
      get() { 
        return '&' + this.getQueryParams
      },
    },
  },
  created() {
    let paramString = ''
    let counter = 0
    for (let param in this.$route.query) {
      if (counter > 0) {
        paramString += '&'
      }
      if (param == 'product') {
        paramString += `productId=${this.$route.query[param]}`
      } else {
        paramString +=`${param}=${this.$route.query[param]}`
      }
      counter++
    }
    this.queryParams = paramString
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.label-section {
  max-width: 346px;
  height: 65px;
  padding: 11px, 0px, 0px, 0px;
  gap: 4px;
  display: inline-block;
  margin: 20px 0;
}
.input-section {
  max-width: 300px;
  height: 75px;
  gap: 4px;
}
.form-content-container {
  margin-left: 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.divider {
  border-top: solid 1px black;
  padding: 20px 0;
}
.total {
  width: 100%;
  margin-left: 14px;
  div {
    display: inline-block;
  }

}
.section-2 {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
}
input[type=radio] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
} 

.radio-line {
  display: flex;
  margin: 15px
}
.cust-button {
  display: inline-block;
  background-color: $yellowLight;
  width: 319px;
  min-width: min-content;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: center;


  cursor: pointer;
  &:hover, :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
  @include media-breakpoint-down(md) {
    width: 220px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin: 10px 0;
  }
}
.button-container {
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  display: flex;

}
.infobox {
  max-width: 599.1px;
  padding: 10px 10px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  letter-spacing: -0.011em;
  text-align: left;
  border: 2px solid $grayLight;
  margin-left: 60px;
}
.info-icon {
  width: 15px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.instruction-container {
  padding: 15px;
}
.link-container {
  text-align: center;
  a {
    color: $redDark;
  }
}
</style>

<!-- /external/transfer-unsuccessful -->
<template>
  <div class="page">
    <Splash/>
    <div class="center">
      <FormContainer header="Verify Your Account">
        <!-- use slot -->
        <template #form-content>

          <div class="form-header">
            <div>
             <img srcset="@/assets/images/failed-error.png 1x, @/assets/images/failed-error-2x.png 2x" src="@/assets/images/failed-error.png" title="Deposits failed"/>
            </div>
            External Transfer Unsuccessful
          </div>
         
          <div class="v-spacer-20"/>
          <div class="failed-msg">
            We cannot process your transfer at this time. 
            <div>
              Please try again.
            </div>
          </div>  
          <div style="text-align: center; margin-top: 30px;">
            Need help? Call us at (808) 844-4545 or toll free at (800) 894-5600
          </div>
          <div class="v-spacer-10"></div>
          <div class="v-spacer-40">
            <div v-if="respErr" class="err-container">
            Something went wrong
            </div>
          </div>
          <div class="button-container">
            <button class="login-btn" @click="retryFunding">
              RETRY
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import Splash from '@/components/Splash.vue'
import funding_api from '@/app/funding_api'
import constants from '@/app/constants'

export default {
  name: 'ExternalTransferUnsuccessful',
  components: {
    FormContainer,
    Splash
},
  data() {
    return {
      processing: false,
      respErr: false, 
      errMsg: '',
      redirect_param: '/external/existing-external-success',
    }
  },
  created () {
    // grab success redirect url
    if (this.$route.query.flow) {
      this.redirect_param = constants.extn_retry_page_success_redirects[this.$route.query.flow]
    }
  },
  methods: {
    ...mapActions('fundnow', ['setProductName', 'setPriorityChecking', 'setCDProduct', 'setEnrollmentSSO']),

    async retryFunding() {
      const resp = await funding_api.postExternalFunding(this.app_id, this.jwtToken)
      this.processing = false
      if (resp.status == 200) {
        // todo success page
        if (resp.data?.response?.enrollment_sso) {
          this.setEnrollmentSSO(resp.data.response.enrollment_sso)
        }
        this.$router.push(this.redirect_param);
      } else if (resp.status == 500) {
        this.respErr = true
        this.errMsg = 'Something went wrong. Please try again.'
      } else {
        this.$router.push('/external/funding-failed')
      }
    },
  },
  computed: {
    ...mapGetters("fundnow", [
      "getCustomerData",
      "getAccounts",
      "getExtSelectedAccount",
      "getCustomerAccounts",
      "getTotal",
      "getAccountType",
      "getRoutingNum",
      "getAccountNum",
      "getAccountNickname",
      "getRealtimeVerifyResp",
      'getFundingID',
      'getJwtToken'
    ]),
    app_id: {
      get() { return this.getFundingID},
    },
    jwtToken: {
      get() { return this.getJwtToken},
    },
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: none;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover, :active {
    background-color: $yellowHover;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
font-size: 24px;
font-weight: 450;
line-height: 36px;
letter-spacing: -0.011em;
text-align: center;

}
.close-container {
  text-align: center;
  margin-top: 60px;
}
</style>

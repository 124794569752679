<!-- Path: /external/new -->
<template>
  <div class="page">
    <SplashBackground/>
    <div class="center">
      <FormContainer header="Verify Your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">
            For your protection, you must verify your account
          </div>
          <form @submit.prevent="submitPage">
            <div class="v-spacer-30"/>
            <div class="section-header">Enter the information for your other bank account</div>
            <div>Total Deposit amount {{ formattedTotal }}</div>
            <div class="v-spacer-20"/>
            <div class="form-content-container">
               <!-- row 1 -->
              <DoubleRadioInput 
                id="account-type" 
                name="account-type" 
                labelOne="Checking" 
                labelTwo="Savings" 
                valueOne="Checking" 
                valueTwo="Savings" 
                v-model="account_type" 
                :disable="processing" 
                label="Account Type" 
                :required="true" 
                :missing="missingAccountType" 
                error="Please select an account type"
                ariaDescribe="double-radio-btn-error"
              />
              <div class="v-spacer-40"/>
              <!-- row 2 -->
                <RoutingNumber ariaDescribe="routing-number-input-err" name="routing-number-input"
                  :disable="this.processing" id="routing-number-input" v-model="routing_num"
                  @blur="validateRoutingNumber()" test="routing-number" label="Routing Number" :missing="missingRoutingNum" :invalid="invalidRoutingNum" :required="true"/>
                <div class="v-spacer-40"/>
              

              <!-- row 3 -->
                <AccountNumber ariaDescribe="account-number-input-err" name="account-number-input"
                  :disable="this.processing" id="account-number-input" v-model="account_num"
                  @blur="validateAccountNumber()" test="account-number" label="Account Number" :missing="missingAccountNum" :invalid="invalidAccountNum" :required="true"/>
                <div class="v-spacer-40"/>

              <!-- row 4 -->
              <AccountNumber ariaDescribe="confirm-account-number-input-err" name="confirm-account-number"
                  :disable="this.processing" id="confirm-account-number-input" v-model="verifyAccountNum"
                  @blur="confirmAccountNumber()" test="confirm-account-number" label="Re-Enter Account Number" :missing="missingConfirmAccount" :invalid="invalidConfirmAccount" :required="true" :confirmAccount="true"/>
              <div class="v-spacer-40"/>
            </div>

            <div class="v-spacer-30"/>          
            <div class="section-2">
              <LabeledCheckbox statement="Save account for future transfers" id="save-account" v-model="save_account" ariaDescribe="checkbox-err"></LabeledCheckbox>
              <!-- only display when a customer has no linked external accounts.  -->
              <div v-if="customer_data.matched_accounts == null" class="v-spacer-30"/>
              <LabeledCheckbox :required="true" v-if="customer_data.matched_accounts === null" statement="I have read and accept the " id="accept-agreement" link="http://fhb.com/etfagreement" linkText="Electronic Transfer Authorization Agreement" v-model="read_agreement" :missing="missingReadAgreement" error="You must read and accept the Electronic Transfer Authorization Agreement to proceed" @missingAgreementEvent="missingAgreementEvent" ariaDescribe="agreement-err"/>
            </div>

            <div class="v-spacer-50"> 
              <div class="error-container" role="alert" v-if="apiErr">
                <div class="image-container">
                  <img srcset="@/assets/images/error-msg-icon.png 1x, @/assets/images/error-msg-icon-2x.png 2x" src="@/assets/images/error-msg-icon.png" title="error message"/> 
                </div>
                <div class="error-message">
                  {{ apiErrMsg }}
                </div>
              </div> 
            </div>  
            <div class="v-spacer-10"/> 
            <div class="button-container">
              <button type="button" class="back-button" @click="goBack" title="go back button">
                <img srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x" src="@/assets/images/backIcon.png" title="go back"/> 
              </button>
              <button :class="processing ? 'processing-button' : 'next-button'" type="submit" :disabled="processing">
                {{ processing ? 'PROCESSING...' : 'NEXT' }}
              </button>
            </div>
          </form>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import SplashBackground from '@/components/Splash.vue'
import DoubleRadioInput from '@/components/Inputs/DoubleRadioInput.vue'
import RoutingNumber from '@/components/Inputs/RoutingNumber.vue'
import AccountNumber from '@/components/Inputs/AccountNumber.vue'
import LabeledCheckbox from '@/components/Inputs/LabeledCheckbox.vue'
import api from '@/app/funding_api'
import jquery from 'jquery'
import helpers from '@/app/helpers.js'

export default {
  name: 'NewAccount',
  components: {
    FormContainer,
    SplashBackground,
    DoubleRadioInput,
    RoutingNumber,
    AccountNumber,
    LabeledCheckbox,
  },
  data() {
    return {
      invalidRoutingNum: false,
      invalidAccountNum: false,
      invalidConfirmAccount: false,
      missingAccountType: false,
      missingRoutingNum: false,
      missingAccountNum: false,
      missingConfirmAccount: false,
      missingSaveAccount: false,
      missingReadAgreement: false,
      processing: false,
      notEditing: false,
      next: false,
      missingRequest: false,
      verifyAccountNum: '',
      apiErr: false,
      apiErrMsg: ''
    }
  },
  created() {

  },
  mounted() {
    jquery('html, body').animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions('fundnow', ['setAccountType', 'setRoutingNum', 'setAccountNum', 'setAccountNickname', 'setSaveAccount', 'setReadAgreement', 'setCustomerData', 'setRealTimeVerification', 'setRealtimeResp', 'setJwtToken', 'setOAuthVerification', 'setOAuthMethod', 'setOAuthURL']),
    missingAgreementEvent(e, v) {
      this.missingReadAgreement = v
    },
    submitPage() {
      this.processing = true
      this.validatePage()
      if (this.next) {
        const fundings = {};
        for (var i in this.opened_accounts) {
          fundings[this.opened_accounts[i].id] = helpers.formatMoneyFromString(this.opened_accounts[i].deposit_amount);
        }
        const funding_id = this.app_id
        const payload = {
          funding_id: funding_id,
          account_id_funding: fundings,
          transfer_type: "EXTERNAL",
          account_number: this.account_num,
          routing_number: this.routing_num,
          account_type: this.account_type,
          save_account_for_future_transfers: this.save_account,
          agreement_accepted: this.read_agreement
        }
        this.verifyAccount(funding_id, this.jwtToken, payload)
       } else {
        this.processing = false
       }
    },

    async verifyAccount(funding_id, token, payload) {
      this.apiErr = false
      const resp = await api.postFundingInit(funding_id, token, payload)
      this.processing = false
      if (resp.status === 200) {
        this.resp_data = resp.data.response
        for (let i = 0; i < this.customer_data.opened_accounts.length; i++) {
          for (let j = 0; j < this.resp_data.accounts.length; j++) {
            if (this.customer_data.opened_accounts[i].account_number == this.resp_data.accounts[j].account_number) {
              this.customer_data.opened_accounts[i].maturity_date = this.resp_data.accounts[j].maturity_date
            }
          }
        }
        // real_time_verification will be an indicator for either having to run oauth or realtime. If oauth is false, then it's realtime
        // create an external account object attached to customer data object in the case OAUTH is used. 
        this.customer_data.selected_external_account =
          {
            account_name: '',
            account_number: this.account_num,
            account_type: this.account_type,
            fi_name: resp.data.response.fi_name,
          }
    
        this.real_time_verification = resp.data.response.verification_modes.online_verification == "true" || resp.data.response.verification_modes.oauth_verification == "true";
        this.oauth_verification = resp.data.response.verification_modes.oauth_verification == "true";
        this.oauth_method = resp.data.response.oauth_method
        this.oauth_url = resp.data.response.oauth_url
        if (resp.data.response.verification_modes.online_verification === "true" || resp.data.response.verification_modes.oauth_verification === "true") {
          this.$router.push('/external/realtime-verify')
        } else if (resp.data.response.verification_modes.online_verification !== "true" || resp.data.response.verification_modes.trial_deposit === "true" || resp.data.response.verification_modes.oauth_verification !== "true") {
          this.$router.push('/external/trial-deposits-verify')
        }
      } else if (resp.status === 422) {
        var remaining_attempts = resp.data?.response?.remaining_attempts
        if (!remaining_attempts || remaining_attempts <= 0) {
          // reached max attempts hard fail  
          var temp_data = this.customer_data
          temp_data.selected_external_account = null
          this.customer_data = temp_data
          this.$router.push('/external/verification-unsuccessful')  
        } else {
          // try again
          this.$router.push('/external/cannot-link-external')
        }
      } else if (resp.status != 500) {
        this.$router.push('/external/cannot-link-external')
      } else {
        this.apiErr = true
        this.apiErrMsg = 'We were unable to link your account due to a system error. Please try again.'
      }
    }, 
    /* Validate account fields and request fields */
    validatePage(){
      this.validateAccountType()      
      this.validateRoutingNumber()
      this.validateAccountNumber()
      this.confirmAccountNumber()
      if (this.customer_data.matched_accounts == null) {
        this.validateReadAgreement()
      }
      this.checkErrors()
    },
    validateAccountType() {
      this.missingAccountType = this.account_type ? false : true
    },
    validateRoutingNumber() {
      this.missingRoutingNum = this.routing_num ? false : true
      // if (!this.missingRoutingNum) {
      //   this.invalidRoutingNum = !validations.validateRoutingNumber(this.routing_num)
      // }
    },
    validateAccountNumber(){
      this.missingAccountNum = this.account_num ? false : true
      // if (!this.missingAccountNum) {
      //   this.invalidAccountNum = !validations.validateAccountNumber(this.account_num)
      // }
    },
    confirmAccountNumber() {
      this.missingConfirmAccount = this.verifyAccountNum ? false : true
      if (!this.missingConfirmAccount) {
        this.invalidConfirmAccount = this.verifyAccountNum != this.account_num
      }
    },
    validateSaveAccount() {
      this.missingSaveAccount = this.save_account ? false : true
    },
    validateReadAgreement() {
      this.missingReadAgreement = this.read_agreement ? false : true
      
    },
    checkErrors() {
      if (
        this.missingAccountNum ||
        this.invalidAccountNum ||
        this.missingRoutingNum ||
        this.invalidRoutingNum || 
        this.missingAccountType ||
        this.missingConfirmAccount ||
        this.invalidConfirmAccount ||
        this.missingReadAgreement 
      ) {
        this.next = false
      } else {
        this.next = true
      }
    },
    goBack() {
      this.$router.push('/initial-deposit')
    },
  },
  computed: {
    ...mapGetters('fundnow', ['getRealtimeVerification','getAppID','getFundingID', 'getJwtToken', 'getTotal', 'getAccountType', 'getRoutingNum', 'getAccountNum', 'getAccountNickname', 'getSaveAccount', 'getReadAgreement', 'getSelectedExtAccount', 'getTotal', 'getCustomerData', 'getRealtimeResp', 'getOAuthVerification', 'getOAuthMethod', 'getOAuthURL']),
    account_type: {
      get() { return this.getAccountType },
      set(v){ this.setAccountType(v)}
    },
    app_id: {
      get() { return this.getFundingID},
      set(v){ this.setFundingID(v)}
    },
    total: {
      get() {
        return helpers.formatMoneyFromString(this.getTotal);
      },
    },
    formattedTotal: {
      get() {
        return helpers.formatMoneyToString(this.total);
      },
    },
    jwtToken: {
      get() { return this.getJwtToken},
      set(v){ this.setJwtToken(v) }
    },
    routing_num: {
      get() { return this.getRoutingNum },
      set(v){ this.setRoutingNum(v)}
    },
    account_num: {
      get() { return this.getAccountNum },
      set(v){ this.setAccountNum(v)}
    },
    account_nickname: {
      get() { return this.getAccountNickname },
      set(v){ this.setAccountNickname(v)}
    },
    save_account: {
      get() { return this.getSaveAccount },
      set(v){ this.setSaveAccount(v)}
    },
    read_agreement: {
      get() { return this.getReadAgreement },
      set(v){ this.setReadAgreement(v)}
    },
    resp_data: {
      get() { return this.getRealtimeResp },
      set(v){ this.setRealtimeResp(v)}
    },
    real_time_verification: {
      get() { return this.getRealtimeVerification},
      set(v){ this.setRealTimeVerification(v)}
    },
    oauth_verification: {
      get() { return this.getOAuthVerification},
      set(v){ this.setOAuthVerification(v)}
    },
    oauth_method: {
      get() { return this.getOAuthMethod},
      set(v){ this.setOAuthMethod(v)}
    },
    oauth_url: {
      get() { return this.getOAuthURL},
      set(v){ this.setOAuthURL(v)}
    },
    opened_accounts: {
      get() { return this.getCustomerData.opened_accounts},
    },
    customer_data: {
      get() { return this.getCustomerData},
      set(v){ this.setCustomerData(v)}
    }
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.form-content-container {
  margin-left: 20px;
  row-gap: 20px;
  margin-top: 10px;
  @include media-breakpoint-down(sm) {
    margin-left: 0px;
  }
}
.section-2 {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
}
input[type=radio] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
} 

.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover, :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover, :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.account-nickname-container {
  padding: 10px 20px;
}

.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}
.error-container {
  height: 50px;
  border: solid 1px $red;
  padding: 10px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  text-align: left;
}
.image-container {
  width: 40px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $redLight;
  img {
    width: 18px;
    height: 18px;
  }
}
.error-message {
  padding-left: 15px;
  color: $red
}
</style>

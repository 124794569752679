<!-- Path: /existing-login -->
<template>
  <div class="page">
    <Splash/>
    <div class="center">
      <FormContainer header="Enroll in Online Banking ">
        <!-- use slot -->
        <template #form-content>
          <div class="recommend-container">
            <div id="first-line-text">
              For your security we strongly recommend:
            </div>
            <div class="list-container">
              <ul>
                <li>
                  Create a unique username and password that is complex, but that you can easily remember. Do not user your name as your User ID.
                </li>
                <li>
                  Do not use the same Username or password that you use for any other websites
                </li>
                <li>
                  Keep First Hawaiian Bank up to date with your contact information. 
                </li>
              </ul>
            </div>
          </div>
          <form id="Q2Enrollment" name="Q2Enrollment" @submit.prevent="submitEnrollment">
            <div class="v-spacer-30"/>
            <!-- Username -->
            <div class="login-input-container">
              <div class="input-container">
                <label for="enrollment_user-id" class="tf-label">
                  Username <span class="fhb-red">*</span>
                </label>
                <input type="text" id="enrollment_user_id" name="enrollment_user_id_name" 
                  test="enrollment_user_id_test" ariaLabel="enrollment_user_id"  
                  v-model="username" :class="missingUsername ? 'll-textfield-error' : 'll-textfield'" 
                  @blur="validateUsername"
                  />
                  <div>
                    Must be 6-32 characters long. Alphanumeric characters and these special characters are allowed: -I@$%^*-_+ (no spaces).
                  </div>
                  <!-- error container -->
                  <div class="v-spacer-20">
                    <div v-if="missingUsername" class="error-container">
                      <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                      <div>Please enter a username</div>
                    </div>
                    <div v-else-if="invalidUsername" class="error-container">
                      <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                      <div>Please enter a valid username</div>
                    </div>
                  </div>
              </div>
            </div>
            <!-- Password -->
            <div class="login-input-container">
              <div class="input-container">
                <label for="password_id" class="tf-label">
                  Password <span class="fhb-red">*</span>
                </label>
                <input type="password" id="password_id" name="password_name" 
                  test="password_test" ariaLabel="password"
                  @blur="validatePassword" v-model="password"
                  :class="missingPassword ? 'll-textfield-error' : 'll-textfield'" autocomplete="current-password"/>
                  <!-- error container -->
                  <div>
                    <div :class="password_valid ? 'pw-valid-container' : 'pw-error-container'">
                      <table>
                        <!-- char range -->
                        <tr>
                          <td>
                            <img v-if="inRange" class="img-col" srcset="@/assets/images/checkmark-green-circle.png 1x, @/assets/images/checkmark-green-circle-2x.png 2x" src="@/assets/images/checkmark-green-circle.png" title="error"/>
                            <img v-else class="img-col" srcset="@/assets/images/checkmark-red-circle.png 1x, @/assets/images/checkmark-red-circle-2x.png 2x" src="@/assets/images/checkmark-red-circle.png" title="error"/>
                          </td>
                          <td class="err-text-col">
                            Must be between 9 and 32 characters
                          </td>
                        </tr>
                        <!-- contains numeric -->
                        <tr>
                          <td>
                            <img v-if="containsNumeric" class="img-col" srcset="@/assets/images/checkmark-green-circle.png 1x, @/assets/images/checkmark-green-circle-2x.png 2x" src="@/assets/images/checkmark-green-circle.png" title="error"/>
                            <img v-else class="img-col" srcset="@/assets/images/checkmark-red-circle.png 1x, @/assets/images/checkmark-red-circle-2x.png 2x" src="@/assets/images/checkmark-red-circle.png" title="error"/>

                          </td>
                          <td class="err-text-col">
                            Must contain at least 1 numeric
                          </td>
                        </tr>
                        <!-- contain lower char -->
                        <tr>
                          <td>
                            <img v-if="containsLower" class="img-col" srcset="@/assets/images/checkmark-green-circle.png 1x, @/assets/images/checkmark-green-circle-2x.png 2x" src="@/assets/images/checkmark-green-circle.png" title="error"/>
                            <img v-else class="img-col" srcset="@/assets/images/checkmark-red-circle.png 1x, @/assets/images/checkmark-red-circle-2x.png 2x" src="@/assets/images/checkmark-red-circle.png" title="error"/>
                          </td>
                          <td class="err-text-col">
                            Password must contain a minimum of 1 lower case characters.
                          </td>
                        </tr>
                        <!-- contain upper char -->
                        <tr>
                          <td>
                            <img v-if="containsUpper" class="img-col" srcset="@/assets/images/checkmark-green-circle.png 1x, @/assets/images/checkmark-green-circle-2x.png 2x" src="@/assets/images/checkmark-green-circle.png" title="error"/>
                            <img v-else class="img-col" srcset="@/assets/images/checkmark-red-circle.png 1x, @/assets/images/checkmark-red-circle-2x.png 2x" src="@/assets/images/checkmark-red-circle.png" title="error"/>
                          </td>
                          <td class="err-text-col">
                            Pasword must contain a minimum of 1 upper case characters.
                          </td>
                        </tr>

                      </table>
                    </div>
                  </div>
              </div> 
              
            </div>
             <!-- Confirm Password -->
             <div class="login-input-container">
              <div class="input-container">
                <label for="confirm_password_id" class="tf-label">
                  Confirm Password <span class="fhb-red">*</span>
                </label>
                <input type="password" id="confirm_password_id" name="confirm_password_name" 
                  test="confirm_password_test" ariaLabel="confirm_password" 
                  :class="missingConfirmPW ? 'll-textfield-error' :'ll-textfield'" autocomplete="current-password"
                  @blur="validateConfirmPassword" v-model="confirm_password"/>
                  <!-- error conatiner -->
                  <div class="v-spacer-40">
                    <div v-if="missingConfirmPW" class="error-container">
                      <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                      <div>Please confirm your password</div>
                    </div>
                    <div v-else-if="invalidConfirmPW" class="error-container">
                      <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                      <div>Your passwords don't match</div>
                    </div>
                  </div>
              </div> 
            </div>
            <div class="v-spacer-20"/>
            <div class="button-container" >
              <button class="enroll-button" type="submit">
                Enroll
              </button>
            </div>
            <div class="v-spacer-20"/>
          </form>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import Splash from '@/components/Splash.vue'

export default {
  name: 'EnrollmentPage',
  components: {
    FormContainer,
    Splash
  },
  data() {
    return {
      password: '',
      confirm_password: '',
      processing: false,
      next: false,

      missingUsername: false,
      invalidUsername: false,
      missingPassword: false,
      invalidPassword: false,
      missingConfirmPW: false,
      invalidConfirmPW: false,
      inRange: false,
      containsNumeric: false,
      containsLower: false,
      containsUpper: false,
      password_valid: false
    }
  },
  created() {

  },
  methods: {
    ...mapActions('fundnow', ['setUsername']),
    togglePasswordVisible() {
      var x = document.getElementById("password");
      x.type = x.type == 'password' ? 'text' : 'password'
    },
    // submit method
    submitEnrollment() {
      console.log('test')
      this.validatePage()
    },
    // validate all fields 
    validatePage() {
      this.validateUsername()
      this.validatePassword()
      this.validateConfirmPassword()
      if (
        this.invalidUsername ||
        this.missingUsername ||
        this.invalidPassword ||
        this.missingPassword ||
        this.invalidConfirmPW ||
        this.missingConfirmPW
      ) {
        console.log('fail')
      } else {
        this.postEnrollment()
      }
    },
    // post API call for form. On success push for user to login.
    postEnrollment(){
      console.log('enrolled')
      this.$router.push('/login')
    },
    // Validate Username
    validateUsername() {
      var user = document.getElementsByName('enrollment_user_id_name')[0]
      this.missingUsername = this.username ? false : true
      if (!this.missingUsername) {
        // Make sure username is within 6-32 chars and doesn't contain certain special chars
        const regex = /^([a-zA-Z0-9~!@$%^*\-_+]{6,32})$/
        if ((this.username.length > 6 & this.username.length < 32) && regex.test(this.username)) {
          this.invalidUsername = false
          user.setAttribute("aria-invalid", "false")
        } else {
          this.invalidUsername = true
          user.setAttribute("aria-invalid", "true")
        }
      } else {
        this.password_valid = false
        user.setAttribute("aria-invalid", "true")
      }
    },
    // Validate password
    validatePassword() {
      var pass = document.getElementsByName('password_name')[0]
      this.missingPassword = this.password ? false : true
      if (!this.missingPassword) {
        // Make sure password is in range and contains at least one numberic, one lowercase and one uppercase
        this.inRange = this.checkRange()
        this.containsNumeric = this.checkNumeric()
        this.containsLower = this.checkLower()
        this.containsUpper = this.checkUpper()
        if (this.inRange && this.containsNumeric && this.containsLower && this.containsUpper) {
          this.password_valid = true
          this.invalidPassword = false
        }
        else {
          this.password_valid = false
          this.invalidPassword = true
        }

        pass.setAttribute("aria-invalid", "false")
      } else {
        this.password_valid = false
        this.invalidPassword = true
        this.inRange = false
        this.containsNumeric = false
        this.containsLower = false
        this.containsUpper = false
        pass.setAttribute("aria-invalid", "true")
      }
    },
    checkRange() {
      if (this.password.length > 9 && this.password.length < 32) {
        return true
      }
      return false
    },
    checkNumeric() {
      const numeric = /\d/
      if (numeric.test(this.password)) {
        return true
      }
      return false
    },
    checkLower() {
      const lowercase = /[a-z]/g
      if (lowercase.test(this.password)) {
        console.log('testing')
        return true
      }
      return false
    },
    checkUpper(){
    const uppercase = /[A-Z]/g
    if (uppercase.test(this.password)) {
        return true
      }
      return false
    },
    // Validate confirm password if it matches with other password
    validateConfirmPassword() {
      var confirm_pw = document.getElementsByName('confirm_password_name')[0]
      this.missingConfirmPW = this.confirm_password ? false : true
      if (!this.missingConfirmPW) {
        if (this.confirm_password == this.password) {
          this.invalidConfirmPW = false
          confirm_pw.setAttribute("aria-invalid", "false")
        } else {
          this.invalidConfirmPW = true
          confirm_pw.setAttribute("aria-invalid", "true")
        }
      } else {
        confirm_pw.setAttribute("aria-invalid", "true")
      }
    }

  },
  computed: {
    ...mapGetters('fundnow', ['getCustomerData', 'getUsername']),
    username: {
      get() { return this.getUsername },
      set(v) { this.setUsername(v) }
    },

  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.form-content-container {
  margin-left: 20px;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.section-2 {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
}
input[type=radio] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
} 

.radio-line {
  margin: 30px 15px; 
  input, label, div {
    display: inline-block;
    justify-self: center;
    vertical-align: middle;
  }
}
.login-button, .enroll-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  &:hover, :active { 
    cursor: pointer;
    background-color: $yellowHover;
  }
}

.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover, :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  margin-top: 0px;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.login-input-container {
  padding: 10px 0px;
  label {
    font-weight: bold;
  }
}

.login-info {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.recovery-info {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.from-retail {
  font-size: 24px;
  text-align: center;
}
.from-retail-header {
  font-size: 28.8px;
}
.img-container {
  text-align: center;
  padding: 30px 0px;
}
.app-text {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  .mobile-apps {
    padding-top: 20px;
  }
}
.appleLogo {
  padding-right: 20px;
  height: 40px;
  width: 134px;
}
.playstoreLogo {
  height: 40px;
  width: 134px;
}
.recommend-container {
  padding: 20px;
  background-color: $orangeFaint;
  text-align: left;
  #first-line-text {
    padding-left: 12px;
  }
}
.ll-textfield {
  max-width: 650px;
}
.input-container {
 margin: 0px 20px;
}
.list-container {
  li {
    margin: 5px 0px;
  }
}
.error-container {
  display: flex;
  color: $red;
  img {
    vertical-align: middle;
    width: 15px;
    height: 15px;
  }
  align-items: center;
  gap: 3px;
}
.pw-error-container {
  color: $red;
  height: max-content;
  background-color: $redLight;
  margin: 10px 0px;
  margin-top: 20px;
  border-left: solid 10px $red; 
  padding: 15px;
  table {
    td {
      padding: 5px 2px;
    }
  }
}
.img-col {
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.err-text-col {
  color: $black;
}
.pw-valid-container {
  height: max-content;
  background-color: $greenLight;
  margin: 10px 0px;
  margin-top: 20px;
  border-left: solid 10px $green; 
  padding: 15px;
  table {
    td {
      padding: 5px 2px;
    }
  }
}
</style>

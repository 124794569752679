<!-- Path: /internal/transfer-unsuccessful -->
<template>
  <div class="page">
    <Splash/>
    <div class="center">
      <FormContainer header="Something Went Wrong">
        <!-- use slot -->
        <template #form-content>

          <div class="form-header">
            <div>
             <img srcset="@/assets/images/sad-face-page.png 1x, @/assets/images/sad-face-page-2x.png 2x" src="@/assets/images/sad-face-page.png" title="Something Went Wrong"/>
            </div>
          </div>
         
          <div class="v-spacer-20"/>
          <div class="subhead">
            There was a problem processing your transfer.
          </div>
          <div class="failed-msg">
              We're working to resolve the issue as soon as possible. We'll contact you with any additional information.
          </div>  
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import Splash from '@/components/Splash.vue'


export default {
  name: 'InternalTransferUnsuccessful',
  components: {
    FormContainer,
    Splash
},
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
    }
  },
  methods: {
    ...mapActions('fundnow', ['setProductName', 'setPriorityChecking', 'setCDProduct']),

    goToExternalMethods() {
      this.$router.push('/initial-deposit')
    },
    goBack() {
      this.$router.push('/initial-deposit')
    },
  },
  computed: {
    ...mapGetters('fundnow', ['getProductName', 'getPriorityChecking', 'getCDProduct']),
    product_name: {
      get() { return this.getProductName },
      set(v){ this.setProductName(v)}
    },
    priority_checking: {
      get() { return this.getPriorityChecking },
      set(v){ this.setPriorityChecking(v)}
    },
    cd_product: {
      get() { return this.getCDProduct },
      set(v){ this.setCDProduct(v)}
    },
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.close-btn {
  width: 200px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: 1px solid  black;
  background-color: $white;
  cursor: pointer;
  &:hover, :active {
    background-color: $grayVlight;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
  font-size: 22px;
  font-weight: 450;
  line-height: 36px;
  letter-spacing: -0.011em;
  text-align: center;
}
.close-container {
  text-align: center;
  margin-top: 60px;
}
.subhead {
  font-size: 26px;
  text-align: center;
  line-height: 36px;
  letter-spacing: -0.011em;
  text-align: center;
  margin-bottom: 20px;
}

</style>

import WelcomeBack from "@/views/External/ReturnToApp/WelcomeBack";
import ReturnAccess from "@/views/External/ReturnToApp/ReturnAccess";
import EnhancedSecurity from "@/views/External/ReturnToApp/EnhancedSecurity";
import CodeToPhone from "@/views/External/ReturnToApp/CodeToPhone";
import CodeToEmail from "@/views/External/ReturnToApp/CodeToEmail";
import SomethingWentWrong from "@/views/External/ReturnToApp/SomethingWentWrong";
import ViewAccountPage from "@/views/External/ReturnToApp/ViewAccountPage.vue";
import auth from "../auth_routes";
import store from "@/store";

const jwtISS = "FUNDING-ISS";

const returnRoutes = {
  getToken() {
    return store.getters["fundnow/getJwtToken"];
  },
  getAppID() {
    return store.getters["fundnow/getFundingID"];
  },
  numOfPhones() {
    if (store.getters["fundnow/getMFAPhones"]) {
      return store.getters["fundnow/getMFAPhones"].length;
    }
    return 0;
  },
  numOfEmails() {
    if (store.getters["fundnow/getMFAEmails"]) {
      return store.getters["fundnow/getMFAEmails"].length;
    }
    return 0;
  },
  getRedirect() {
    return "/";
  },
  loadRoutes(routes) {
    routes.push(
      {
        path: "/return/access-application/:id",
        name: "Access Application",
        component: ReturnAccess,
      },
      {
        path: "/return/enhanced-security",
        name: "Enhanced Security",
        component: EnhancedSecurity,
        beforeEnter: (to, from, next) => {
          // only should be able to go to next page if successful submission (since no jwt token yet)
          if (this.numOfEmails() > 0 || this.numOfPhones() > 0) {
            next();
          } else {
            next({
              path: this.getRedirect(),
            });
          }
        },
      },
      {
        path: "/return/mfa-mobile",
        name: "MFA Mobile",
        component: CodeToPhone,
        beforeEnter: (to, from, next) => {
          // only should be able to go to next page if successful submission (since no jwt token yet)
          if (this.numOfPhones() > 0) {
            next();
          } else {
            next({
              path: this.getRedirect(),
            });
          }
        },
      },
      {
        path: "/return/mfa-email",
        name: "MFA Email",
        component: CodeToEmail,
        beforeEnter: (to, from, next) => {
          // only should be able to go to next page if successful submission (since no jwt token yet)
          if (this.numOfEmails() > 0) {
            next();
          } else {
            next({
              path: this.getRedirect(),
            });
          }
        },
      },
      {
        path: "/return/mfa/something-went-wrong",
        name: "Something Went Wrong",
        component: SomethingWentWrong,
        beforeEnter: (to, from, next) => {
          // only should be able to go to next page if successful submission (since no jwt token yet)
          if (this.numOfEmails() > 0 || this.numOfPhones() > 0) {
            next();
          } else {
            next({
              path: this.getRedirect(),
            });
          }
        },
      },
      {
        path: "/welcome-back",
        name: "Welcome Back",
        component: WelcomeBack,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(
            to,
            from,
            next,
            jwtISS,
            this.getToken(),
            this.getRedirect()
          );
        },
      },
      // for when funding is completed
      {
        path: "/view-account",
        name: "View Account",
        component: ViewAccountPage,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(
            to,
            from,
            next,
            jwtISS,
            this.getToken(),
            this.getRedirect()
          );
        },
      }
    );
    return routes;
  },
};

export default returnRoutes;

<template>
  <footer class="footer-bg no-print" id="footer">
    <div class="footer-header"></div>

    <div class="footer-body">
      <div class="footer-content container">
        <div class="footer-links">
          <ul>
            <li>
              <a class="footer-link" href="https://www.fhb.com/en/contact-us">
                <img class="map-pin"
                  srcset="@/assets/images/locationPinIcon.png 1x, @/assets/images/locationPinIcon-2x.png 2x"
                  src="@/assets/images/locationPinIcon-2x.png" aria-hidden="true" />
                <span>Need Help? Contact Us</span>
              </a>
            </li>

            <li>
              <a class="footer-link" href="https://locations.fhb.com/">
                <img class="map-pin"
                  srcset="@/assets/images/locationPinIcon.png 1x, @/assets/images/locationPinIcon-2x.png 2x"
                  src="@/assets/images/locationPinIcon-2x.png" aria-hidden="true" />
                <span>Find a Branch/ATM</span>
              </a>
            </li>

            <li>
              <a class="footer-link" href="https://www.fhb.com/en/privacy-policy">
                <img class="padlock" srcset="@/assets/images/padlockIcon.png 1x, @/assets/images/padlockIcon-2x.png 2x"
                  src="@/assets/images/padlockIcon-2x.png" aria-hidden="true" />
                <span>Privacy Policy</span>
              </a>
            </li>

            <li>
                <img class="shield" srcset="@/assets/images/shieldIcon.png 1x, @/assets/images/shieldIcon-2x.png 2x"
                  src="@/assets/images/shieldIcon-2x.png" aria-hidden="true" />Your information is protected with 256-bit AES encryption.
            </li>
          </ul>

        </div>
        <div class="hr" />
        <div class="footer-closing">
          <div class="footer-logo">
            <a href="https://www.fhb.com/"><img class="header-logo"
                srcset="@/assets/images/FHBColorLogo-Footer.png 1x, @/assets/images/FHBColorLogo-Footer-2x.png 2x"
                src="@/assets/images/FHBColorLogo-Footer-2x.png" alt="First Hawaiian Bank home logo"
                title="FHB home" /></a>
          </div>
          <div class="blend">
            <span class="member-fdic">Member FDIC</span> <span class="ehl">
              <img class="ehl-icon" srcset="@/assets/images/ehl.png 1x, @/assets/images/ehl-2x.png 2x"
                src="@/assets/images/ehl-2x.png" aria-hidden="true" /> Equal Housing Lender</span>
          </div>
          <div class="footer-text">
            <span>&copy;</span> {{new Date().getFullYear()}} First Hawaiian Bank
          </div>

        </div>

      </div>
    </div>

  </footer>

</template>

<script>
export default {
  name: 'FHBFooter',
  props: {
    type: String
  },
  components : {

  },
  data () {
    return {
      filteredIngredients: [
        {name: "paper", checkbox: false},
        {name: "salt", checkbox: false}
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
.footer-bg {
  width: 100%;
}
.footer-header {
  height: 8em;
  overflow: hidden;
  position: relative;
  z-index: 3;

  background-color: var(--grayFooterBg);
  background-image: url('../assets/images/white-top-right-corner.png');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 100% 100px;
  top: 100px;
  position: relative;
  width: 100%;
  height: 100px;
}
@media (max-width: 767.9px) {
  .footer-header {
    background-size: 100% 50px;
    height: 50px;
    top: 50px;
  }
}
.footer-body {
  height: auto;
  background: $grayFooterBg;
  padding-bottom: 4em;
  padding-top: 6em;
}
.footer-content {
  /* outline: 1px dotted orange; */
  padding-top: 0.5em;
}
.footer-links {
  column-gap: 2em;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  line-height: 1.5;
  margin-bottom: 2.5em;
  row-gap: 1em;
  text-align: left;
  // text-align: center;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    li {
      padding-right: 30px;
    }
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 2em;
    text-align: left;
  }
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }

  p {
    margin: 0;
  }
}
a,
a:visited {
  color: $black;
}
.footer-link {
  min-width: 102px;
  position: relative;

  @include media-breakpoint-up(sm) {
    margin-top: 0;
  }

  &.footer-help {
    flex-basis: 100%;
    margin-bottom: 1em;
    margin-top: 0;

    @include media-breakpoint-down(md) {
      text-align: center;

      strong {
        display: block;
        margin-bottom: 0.25em;
      }
    }
  }
}
a.footer-link {
  color: $black;
  text-decoration: unset;
  white-space: nowrap;

  > span {
    color: inherit;
    text-decoration: underline;
  }

  &:hover,
  &:focus,
  &:active {
    > span {
      color: $grayDark;
      text-decoration: underline;
    }
  }
}
.footer-closing {
  color: $grayDark;
  font-size: 0.95rem;
  font-weight: 600;
  text-align: left;
  margin-top: 25px;

  @include media-breakpoint-up(md) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1em;
  }
}
.footer-logo {
  text-align: center;
  @include media-breakpoint-up(md) {
    flex-basis: auto;
    text-align: left
  }
}
.footer-logo img {
  height: auto;
  width: 226px;

  @include media-breakpoint-up(md) {
    width: 286px;
  }
}
.blend {
  text-align: center;
  @include media-breakpoint-down(md) {
    margin: 2em 0;
  }
  @include media-breakpoint-up(lg) {
    text-align: right;
  }
}
.footer-text {
  line-height: 150%;
  text-align: center;
  @include media-breakpoint-down(lg) {
    flex-basis: 100%;
  }
  @include media-breakpoint-up(lg) {
    text-align: right;
  }
}
.footer-links img {
  margin-right: 5px;
  margin-bottom: -3px;
}
.member-fdic {
  margin-right: 1em;
}
.ehl-icon {
  height: auto;
  margin-bottom: -3px;
  width: 20px;
}
.map-pin {
  height: auto;
  width: 12px;
}
.padlock {
  height: auto;
  width: 11px;
}
.shield {
  height: auto;
  width: 14px;
}
.header-logo {
    max-width: 286px;
    max-height: 42.5px;
    width: 100%;
    height: 100%;
  }
</style>

<!-- Path: /external/realtime-authentication -->
<template>
  <div class="page">
    <Splash/>
    <div class="center">
      <FormContainer header="Verify your Account">
        <!-- use slot -->
        <template #form-content>

          <div class="form-header">
            <div>
             <img srcset="@/assets/images/account_created.png 1x, @/assets/images/account_created-2x.png 2x" src="@/assets/images/account_created.png" title="Accounts created"/>
            </div>
            <div>
              Transfer from my account at another bank
            </div>
          </div>
         
          <div class="v-spacer-40"/>
          <div class="sub-header">
            <div id="enter-credentials">
              Enter your credentials
            </div>
            <div id="note">
              Provide your credentials to enable First Hawaiian Bank to retrieve your financial data from {{ realtime_resp.fi_name }}. This may take a few minutes.
            </div>
          </div> 
          <div class="v-spacer-20"/>
          <form>
            <div class="realtime-resp-ctn">
              <!-- loop through params returned in realtime response -->
              <!-- skip the first two params as these are for logging the user in -->
              <div v-for="(param, index) in realtime_verify_resp.TwoFARealtimeAcctVerificationParam.Param" :key="index" >
                <RealTimeInput 
                  v-if="param.Name != realtime_resp.external_login_params.FILoginParametersInfo[0].CredentialsParamId && param.Name != realtime_resp.external_login_params.FILoginParametersInfo[1].CredentialsParamId"
                  :label="param.Value" :id="`param-${index}`" :ariaDescribe="`${param.Value}-err`"
                  :name="`name-1-`" :test="`test-1`" 
                  :required="true" @blur="validateField(index)"
                  v-model="param.answer"
                  :missing="missingField[index]"
                />
              </div>
            </div>  
          </form>       
          <div class="v-spacer-40"/>
          <div class="v-spacer-40">
            <div v-if="respErr" class="error">
              An error occurred, please try again.
            </div>
          </div>
          <div class="button-container">
            <button type="button" :class="processing ? 'processing-button' : 'login-btn no-print'"  @click="submit">
              {{ processing ? 'PROCESSING...' : 'CONTINUE' }}
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import Splash from '@/components/Splash.vue'
import env from '@/app/env'
import jquery from 'jquery'
import RealTimeInput from '@/components/Inputs/RealTimeInput.vue'
import funding_api from '@/app/funding_api'

export default {
  name: 'RealtimeAuthenticationLogin',
  components: {
    FormContainer,
    Splash,
    RealTimeInput,
},
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      account: '',
      loginURL: env.loginURL(),
      missingField: [],
      respErr: false,
      passwordParam: '',
      usernameParam: ''
    }
  },
  mounted( ) {
    jquery('html, body').animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions('fundnow', ['setRealtimeResp', 'setFundingID', 'setRealtimeVerifyResp', 'setCustomerData']),

    async submit() {
      await this.checkErrors()
      if (this.next) {
        await this.postRealtimeData()
      }
    },
    async postRealtimeData() {
      this.respErr = false
      // create payload and array of params to pass
      let payload = {
        from_start: false,
        params: [],
      }

      /* Loop through first realtime call response to get login credentials to pass into payload. 
      *  Login credentials are needed in the payload everytime the route is pinged, alonged with the new params (such as security questions)
      */
      for (let param of this.realtime_resp.external_login_params.FILoginParametersInfo) {
        // for login
        if (param.CredentialsParamType == 'login') {
          this.usernameParam = param.CredentialsParamId
          payload.params.push(
            {
                param_id: param.CredentialsParamId,
                crypt_param_nv: {
                    param_id: param.CredentialsParamId,
                    crypt_param_val: {
                        crypt_type: "none",
                        crypt_val: param.value
                    }
                }
            },
          )
        }
        // for password
        else if (param.CredentialsParamType == 'password') {
          this.passwordParam = param.CredentialsParamId
          payload.params.push(
            {
                param_id: param.CredentialsParamId,
                crypt_param_nv: {
                    param_id: param.CredentialsParamId,
                    crypt_param_val: {
                        crypt_type: "none",
                        crypt_val: param.value
                    }
                }
            },
          )
        }
      }
      // Loop through any following realtime calls (calls made after first call) for rest of parameters (not login credentials)
      for (let p in this.realtime_verify_resp.TwoFARealtimeAcctVerificationParam.Param) {
          if (this.realtime_verify_resp.TwoFARealtimeAcctVerificationParam.Param[p].Name != this.usernameParam  && this.realtime_verify_resp.TwoFARealtimeAcctVerificationParam.Param[p].Name != this.passwordParam) {
            payload.params.push(
              {
                  param_id: this.realtime_verify_resp.TwoFARealtimeAcctVerificationParam.Param[p].Name,
                  crypt_param_nv: {
                      param_id: this.realtime_verify_resp.TwoFARealtimeAcctVerificationParam.Param[p].Name,
                      crypt_param_val: {
                          crypt_type: "none",
                          crypt_val: this.realtime_verify_resp.TwoFARealtimeAcctVerificationParam.Param[p].answer
                      }
                  }
              },
            )
          }
        }
      this.processing = true
      const resp = await funding_api.postRealtimeData(this.app_id, this.jwtToken, payload)

      if (resp.status == 200) {
        // if not approved and param array existif ()
        if (resp.data.response.TwoFARealtimeAcctVerificationParam.Param && resp.data.response.VerificationStatus != 'Approved') {
          // if requires approval then still in flow reset verify resp
          if (resp.data.response.VerificationStatus == 'Requires Approval') {
            // if requires approval still and param array isn't empty, set the verify resp to the most recently received response (since it would contain updated information)
            this.realtime_verify_resp = resp.data.response
          } else if (resp.data.response.VerificationStatus == 'Suspended' || resp.data.response.VerificationStatus == 'Denied') { // if not requires approval or approved then there is an error
            this.$router.push('/external/realtime-unsuccessful')
          } else {
            this.respErr = true
          }
            
        // if approved and no param array, the flow is done
        } else if (resp.data.response.VerificationStatus == 'Approved') {
          // create an external accounts array to store newly linked account info that can be shown on success page
          this.customer_data.selected_external_account = {
              aba: resp.data.response.ABA,
              account_name: '',
              account_number: resp.data.response.AccountNumber,
              account_type: '',
              fi_name: this.realtime_resp.fi_name,
              internal_acc_id: ''
            }
          // push to success page
          this.$router.push('/external/realtime-confirm-account')
        }
      } else if (resp.status != 500) {
        // if not either above, then reroute
        this.$router.push('/external/realtime-unsuccessful')
      } else {
        this.respErr = true
      }
      this.processing = false
    },
    // function to validate a single field
    validateField(index) {
      const field = jquery(`#param-${index}`)
      this.missingField[index] = this.realtime_verify_resp.TwoFARealtimeAcctVerificationParam.Param[index].answer ? false : true
      
      // set aria validation depending on if field is missing
      if (this.missingField[index]) {
        field.attr("aria-invalid", "true")
      } else {
        field.attr("aria-invalid", "false")
      }
    },
    // returns a promise that loops through param array and validates each field
    validatePage() {
      return new Promise((resolve) => {
        for (let i = 0; i < this.realtime_verify_resp.TwoFARealtimeAcctVerificationParam.Param.length; i++) {
          this.validateField(i)
        }
        resolve()
      })
    },
    // check errors on the page
    async checkErrors() {
      await this.validatePage()
      this.$nextTick(() => {
        this.errCnt = jquery('.error').length;
        // check if any invalid or missing fields
        if (this.errCnt === 0) {
          this.next = true
        } else if (this.errCnt === 1) {
          this.next = false
        } else {
          this.next = false
        }
      })
    },
  },
  computed: {
    ...mapGetters('fundnow', ['getExtSelectedAccount', 'getCustomerData', 'getTotal', 'getRealtimeResp', 'getFundingID', 'getJwtToken', 'getRealtimeVerifyResp']),
    customer_data: {
      get() { return this.getCustomerData },
      set(v) { return this.setCustomerData(v) },
    },
    realtime_verify_resp: {
      get() { return this.getRealtimeVerifyResp },
      set(v) { return this.setRealtimeVerifyResp(v) },
    },
    realtime_resp: {
      get() { return this.getRealtimeResp },
      set(v) { return this.setRealtimeResp(v) },
    },
    app_id: {
      get() { return this.getFundingID},
      set(v){ this.setFundingID(v)}
    },
    jwtToken: {
      get() { return this.getJwtToken},
    },
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.grid-table {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 15px;
  border-bottom: solid 1px $gray;
  
  #headers {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  .left-col {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;

  }
  .right-col {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
}
.grid-table-total {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 15px;
  
  #headers {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  .left-col {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;

  }
  .right-col {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
}
.table-spacing {
  margin-top: 20px
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: 1px solid $yellowLight;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover, :active {
    background-color: $yellowHover;
  }
}
.cd-info {
  font-weight: normal;
  font-size: 15px;
}
.realtime-resp.ctn {
  display: flex;
}
.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover, :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.sub-header {
  text-align: center;
  #enter-credentials {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.011em;
    text-align: center;
  }
 
  #id {
    width: 600px;
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: center;

  }
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
}
</style>

<template>
  <div role="complementary" aria-labelledby="skiptomain" class="skip-link">
    <a
      id="skiptomain"
      href="#main"
      tabindex="0"
      class="element-invisible element-focusable"
    >
      Skip to main content
    </a>
  </div>
  <div>
    <div v-if="isLocalhost" class="env-banner env-localhost">localhost</div>
    <div v-if="isDev" class="env-banner env-dev">development</div>
    <div v-if="isStaging" class="env-banner env-staging">staging</div>
  </div>
  <div>
    <FDICHeader
      v-if="
        this.$route.path !== '/return/enhanced-security' &&
        this.$route.path !== '/return/mfa-email' &&
        this.$route.path !== '/return/mfa-mobile' &&
        this.$route.path !== '/return/mfa/something-went-wrong' &&
        this.$route.path !== '/session-expired'
      "
    />
    <Header
      v-if="
        this.$route.path !== '/return/enhanced-security' &&
        this.$route.path !== '/return/mfa-email' &&
        this.$route.path !== '/return/mfa-mobile' &&
        this.$route.path !== '/return/mfa/something-went-wrong' &&
        this.$route.path !== '/session-expired'
      "
    />
    <router-view />
    <Footer
      v-if="
        this.$route.path !== '/return/enhanced-security' &&
        this.$route.path !== '/return/mfa-mobile' &&
        this.$route.path !== '/return/mfa-email' &&
        this.$route.path !== '/return/mfa/something-went-wrong' &&
        this.$route.path !== '/session-expired'
      "
    />
  </div>
</template>

<script>
// import { datadogLogs } from '@datadog/browser-logs';
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import funding_api from "@/app/funding_api";
import FDICHeader from "./components/FDICHeader.vue";

export default {
  name: "App",
  components: { Footer, Header, FDICHeader },
  data() {
    return {
      isDev: false,
      isStaging: false,
      isLocalhost: false,
      env: "prod",
      timeoutWarning: "",
      timeoutLogOut: "",
      timer: "",
      hours: "",
      minutes: "",
    };
  },
  created() {
    if (window.location.href.indexOf("dev.") > -1) {
      this.isDev = true;
      this.env = "dev";
    } else if (window.location.href.indexOf("staging.") > -1) {
      this.isStaging = true;
      this.env = "staging";
    } else if (window.location.href.indexOf("localhost") > -1) {
      this.isLocalhost = true;
      this.env = "localhost";
    }
    let loginScript = document.createElement("script");
    loginScript.setAttribute("type", "text/javascript");
    loginScript.setAttribute(
      "src",
      "https://cds-sdkcfg.onlineaccess1.com/common.js"
    );
    document.head.insertBefore(loginScript, document.head.firstChild);

    // Setup client side logging
    // datadogLogs.init({
    //   clientToken: 'pubbd1377c9304b7ba925f84e2be008783c',
    //   site: 'datadoghq.com',
    //   service: 'boe-admin-fe',
    //   env: this.env,
    //   forwardErrorsToLogs: true,
    //   sampleRate: 100
    // });
  },
  mounted() {
    this.refresh();
  },
  watch: {
    // Watch token for updates. This is needed since the token is stored on /initial-deposits after the app.vue file is mounted
    jwt_token: function () {
      this.refresh();
    },
  },
  computed: {
    ...mapGetters("fundnow", ["getJwtToken", "getFundingID"]),
    jwt_token: {
      get() {
        return this.getJwtToken;
      },
      set(v) {
        this.setJwtToken(v);
      },
    },
    funding_id: {
      get() {
        return this.getFundingID;
      },
      set(v) {
        this.setFundingID(v);
      },
    },
  },
  methods: {
    ...mapActions("fundnow", ["resetAll", "setJwtToken", "setFundingID"]),
    isTimeoutIgnored() {
      return (
        this.$route.name == "Fund Now Home" ||
        this.$route.name == "Test Driver" ||
        this.$route.name == "Login to Online Banking"
      );
    },
    refresh() {
      console.log("refresh()...");
      // refreshed timer
      clearTimeout(this.timeoutWarning);
      clearTimeout(this.timeoutLogOut);
      // only run if there is a session in place
      if (this.jwt_token) {
        this.createSessionTimer();
      }
    },
    isExpired() {
      const jwtToken = this.jwt_token;
      if (jwtToken != null) {
        const parts = jwtToken.split(".");
        if (parts.length > 1) {
          const claims = JSON.parse(atob(parts[1]));
          const warningEpoch = Math.floor(Date.now() / 1000);
          return claims.exp < warningEpoch;
        }
      }
      return false;
    },
    // adjust the session timer based on JWT in store
    createSessionTimer() {
      const jwtToken = this.jwt_token;
      // Get expiration time of token
      const parts = jwtToken.split(".");
      if (parts.length > 1) {
        var jwt = JSON.parse(atob(parts[1]));
        this.timer = jwt;
      }

      // set expiration time using jwt token to be usable with setTimeout
      const logoutTime = jwt ? jwt.exp * 1000 - Date.now() : "";
      // set warning time (warn 5 minutes prior to expiration)
      const warningTime = jwt ? logoutTime - 1 * 60 * 1000 : "";

      // set vars to display for sweet alert warning
      this.hours = new Date(jwt.exp * 1000).getHours();
      this.minutes = new Date(jwt.exp * 1000).getMinutes();

      // If the token is going to timeout the next time the warning is going to display, skip and only use timeoutLogOut
      this.timeoutWarning = setTimeout(() => {
        if (this.isTimeoutIgnored()) {
          return;
        }
        if (this.isExpired()) {
          console.log("Expired, timing out");
          this.displaySessionTimedOut();
        } else {
          console.log("Not expired, showing warning");
          this.displaySessionTimeoutWarning();
        }
      }, warningTime);

      this.timeoutLogOut = setTimeout(() => {
        if (this.isTimeoutIgnored()) {
          return;
        }
        this.displaySessionTimedOut();
      }, logoutTime);
    },
    displaySessionTimeoutWarning() {
      console.log("send warning...");
      const imagePath = require("@/assets/images/timeout-2x.png");
      Swal.fire({
        title: "Still working?",
        text: `Please select "Continue Funding" to finish funding your new account(s). If not, your session will expire 5 minutes for your security.`,
        imageUrl: imagePath,
        imageWidth: 97,
        imageHeight: 125,
        confirmButtonText: "Continue Funding",
        showClass: {
          popup: "ani mate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // ping token route to refresh token and extend session
          const resp = await funding_api.postRefreshToken(this.jwt_token);
          if (resp) {
            if (resp.status == 200) {
              this.jwt_token = resp.data.access_token;
            } else {
              this.$router.push("/session-expired");
            }
          }
        }
      });
    },
    // close warning swal and route to page
    displaySessionTimedOut() {
      Swal.close();
      this.$router.push("/session-expired");
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}
a {
  word-wrap: break-word;
}
#app {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial,
    Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  line-height: 1.5;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}
a,
a:visited,
a:link,
button {
  color: $black;
}
.skip-link a {
  color: $redDark;
}
.tel {
  white-space: nowrap;
}
select {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial,
    Helvetica, sans-serif;
}
.container {
  @include media-breakpoint-up(lg) {
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
  }
  @include media-breakpoint-down(lg) {
    margin-left: 2em;
    margin-right: 2em;
  }
  @include media-breakpoint-down(sm) {
    margin-left: 1.5rem;
    margin-right: 1.5em;
  }
  @include media-breakpoint-up(xxl) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }
}
h1 {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial,
    Helvetica, sans-serif;
}
h2 {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial,
    Helvetica, sans-serif;
}
h3 {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial,
    Helvetica, sans-serif;
}
input {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial,
    Helvetica, sans-serif;
}
button,
.swal2-popup {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial,
    Helvetica, sans-serif;
}
@font-face {
  font-family: "CircularXXWeb-Book";
  src: url("./assets/fonts/CircularXXWeb-Book.woff") format("woff");
}
@font-face {
  font-family: "CircularXXWeb-Book";
  src: url("./assets/fonts/CircularXXWeb-Book.woff2") format("woff2");
}
@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff") format("woff");
}
@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "CircularXXTT-Bold";
  src: url("./assets/fonts/CircularXXTT-Bold.ttf") format("woff2");
}
@font-face {
  font-family: "CircularXXTT-Book";
  src: url("./assets/fonts/CircularXXTT-Book.ttf") format("woff2");
}
.env-banner {
  display: block;
  position: absolute;
  text-align: center;
  height: 25px;
  width: 100%;
  color: $white;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
}
.env-localhost {
  background-color: rgb(37, 161, 66, 0.5);
}
.env-dev {
  color: black;
  background-color: rgb(255, 252, 55, 0.5);
}
.env-staging {
  background-color: rgb(161, 37, 37, 0.5);
}
.fhb-red {
  color: $redSup;
}
.hr {
  border-top: 1px solid $gray;
  height: 1px;
  margin: 2em 0;
}
.btn-container {
  align-items: center;
  column-gap: 2em;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  text-align: center;
}
.back-btn {
  align-items: center;
  background-color: $white;
  color: $black;
  display: flex;
  width: 63px;
  height: 40px;
  justify-content: center;
  border: 1px solid $grayDark;
  border-radius: 20px;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.back-icon {
  height: auto;
  width: 8px;
}
.goto-fhb-btn {
  align-items: center;
  background-color: $white;
  border: 1px solid $grayDark;
  border-radius: 20px;
  color: $black;
  display: flex;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 209px;

  &:hover,
  &:active,
  &:focus {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.goto-fhb-text {
  color: $black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
  position: relative;
  text-align: center;
}
.continue-btn {
  align-items: center;
  display: flex;
  background: $yellowLight;
  border: none;
  border-radius: 20px;
  color: $black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.029em;
  line-height: 1.5;
  text-align: center;
  width: 170px;

  @include media-breakpoint-down(sm) {
    flex: 1;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.default-btn {
  align-items: center;
  display: flex;
  background: $yellowLight;
  border-radius: 20px;
  color: $black;
  width: 140px;
  height: 40px;
  justify-content: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.029em;
  margin: auto;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    flex: 1;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.continue-text {
  font-weight: 700;
  letter-spacing: 0.05em;
  position: relative;
  text-transform: uppercase;
}
.v-spacer-80 {
  height: 80px;
}
.v-spacer-60 {
  height: 60px;
}
.v-spacer-50 {
  height: 50px;
}
.v-spacer-40 {
  height: 40px;
}
.v-spacer-30 {
  height: 30px;
}
.v-spacer-20 {
  height: 20px;
}
.v-spacer-15 {
  height: 15px;
}
.v-spacer-10 {
  height: 10px;
}
.v-spacer-5 {
  height: 5px;
}
.separator {
  color: $gray;
  font-weight: 300;
}
.field-group {
  position: relative;
}
.field-group + .field-group {
  @include media-breakpoint-down(lg) {
    margin-top: 2em;
  }
}
.section-title {
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 2.75rem;
  }
}
.section-title2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.5em;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.8rem;
  }
}
.panel-icon {
  display: inline-block;
  width: 13px;
}
.panel-icon:hover {
  cursor: pointer;
}
.panel-header {
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: 0;
}
.panel-header:hover {
  cursor: pointer;
}
.panel-title {
  align-items: center;
  display: grid;
  grid-gap: 1.6em;
  grid-template-columns: 13px auto;
  margin-bottom: 1em;

  .panel-icon:hover,
  .panel-icon:focus {
    transform: scale(1.2);
    transition: 200ms ease;
  }

  .panel-icon:active {
    transform: rotate(180deg);
    transition: 200ms ease;
  }
}
.panel-title + div + div > .panel-title {
  margin-top: 4em;
}
.tf-label {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}
.icon-validation {
  bottom: 2.5em;
  height: 0;
  padding-right: 1em;
  position: relative;
  text-align: right;
  z-index: 2;

  img {
    height: auto;
    width: 24px;
  }

  @include media-breakpoint-down(sm) {
    bottom: 2.2em;
  }
}
.icon-validation,
.ll-textfield,
.tf-note {
  @include media-breakpoint-up(lg) {
    max-width: 548px;
  }
}
.input-container {
  .ll-textfield {
    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }
  }
}
.grid-container,
.ll-row {
  .icon-validation,
  .ll-textfield,
  .tf-note {
    @include media-breakpoint-up(lg) {
      max-width: unset;
    }
  }
}
.ll-textfield,
.ll-textfield-error {
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;

  @include media-breakpoint-up(sm) {
    height: 46px;
  }
}
.ll-textfield {
  background-color: $white;
  border: 1px solid $grayMid;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
}
.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
}
.ll-textfield-money {
  padding-left: 26px;
}
.ll-prefix-wrap {
  position: relative;
}
.tf-dollar-prefix {
  align-items: center;
  height: 40px;
  position: absolute;
  display: flex;
  left: 1em;
  bottom: 0;
  width: 12px;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }
}
.ll-select-error,
.ll-select {
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 42px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }
}
.ll-select {
  background-color: $white;
  border: 1px solid $grayMid;
}
.ll-select-error {
  background: $white;
  border: 1px solid $red;
}
.ll-item {
  position: relative;

  > input[type="checkbox"] {
    margin-left: 1px;
  }
}
.ll-item + .ll-item {
  @include media-breakpoint-down(sm) {
    margin-top: 1.5em;
  }
}
.page-error-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  background-color: $white;
  color: $red;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5;
  text-align: left;
  border: 1px solid $red;
  display: grid;
  grid-template-columns: 50px auto;
}
.page-error-icon-container {
  background-color: $redFaint;
  text-align: center;
}
.page-error-icon {
  height: auto;
  padding-top: 1.2em;
  width: 15px;
}
.page-error-msg {
  padding: 1em;
}
.dump {
  text-align: left;
}
.tf-note {
  margin-top: 4px;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  a {
    &:hover,
    &:active,
    &:focus {
      color: $redDark;
    }
  }
}
.footnote {
  background-color: $orangeFocus;
  color: $black;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  grid-gap: 0.25em;
  line-height: 1.5;
  margin-top: 30px;
  padding: 25px;
}
.disclosure-bottom strong {
  font-size: 0.95rem;
  font-weight: 700;
  letter-spacing: 0.029em;
}
.disclosure-bottom {
  border: 1px solid $grayMid;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  border-radius: 3px;
  padding: 15px;

  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.form-error-msg {
  align-items: center;
  display: grid;
  grid-column-gap: 0.35em;
  grid-template-columns: 15px auto;
  margin-top: 3px;

  .form-error-icon {
    height: auto;
    width: 15px;
  }
}
.form-error-txt {
  display: inline-block;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: $redDark;
}
.toggle-btn-container {
  font-size: 1rem;
  row-gap: 1em;
  column-gap: 1em;
}
.toggle-btn-container > * {
  margin: 12px auto;
  column-gap: 0.25em;
  display: flex;

  @include media-breakpoint-down(md) {
    // flex-basis: 100%;
  }
}
.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}
.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 25px;
}
.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}
.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}
.toggle-btn-up:hover {
  cursor: pointer;
}
.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
}
.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}
.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
}
.sys-err-msg {
  border: 1px solid $red;
  color: $red;
  padding: 0.75em 1.5em;
  text-align: center;
}
.sys-err-icon {
  margin-right: 10px;
}
.sys-err-list {
  display: inline-block;
  text-align: left;
}
.txt-bold {
  font-weight: 800;
}
body img[src*="turn"] {
  bottom: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
}
.all-caps {
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.header-logo,
.submitted-logo {
  height: auto;
  @include media-breakpoint-up(md) {
    padding-bottom: 12px;
    width: 225px;
  }
  @include media-breakpoint-down(md) {
    width: 150px;
  }
}
.page {
  height: 100%;
  // min-height: max-content;
  min-height: 100vh;
}
.content-container {
  align-content: center;
  background-image: url("assets/images/orange@1x.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0 2em;
  width: 100%;

  @include media-breakpoint-down(sm) {
    background-size: 800px;
  }
}
.overlay-content {
  height: auto;
  margin: 2em auto;
  padding: 2em;
  position: relative;
  text-align: center;
  @include media-breakpoint-up(sm) {
    background: $white;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-width: 500px;
    width: calc(100% - 4em);
  }
  @include media-breakpoint-up(lg) {
    max-width: 700px;
  }
}
.submitted-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.submitted {
  height: auto;
  margin: 2em auto;
  padding: 2em;
  text-align: center;
  @include media-breakpoint-up(sm) {
    background: $white;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-width: 500px;
    width: calc(100% - 4em);
  }
  @include media-breakpoint-up(lg) {
    max-width: 700px;
    padding: 2em 4em;
  }
}
.overlay-splash {
  margin: 2em 0 0.5em;
}
.overlay-title {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}
.overlay-subtitle {
  margin-top: 0.25em;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}
.overlay-subtitle {
  margin-top: 20px;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.011em;
  text-align: center;
}
.overlay-body {
  display: inline-block;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin-top: 15px;
}
.return-fhb-link-btn {
  background-color: $white;
  border-radius: 20px;
  display: block;
  min-height: 40px;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin: auto;
  padding: 0.8em 1.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 240px;
  border: 1px solid $grayDark;
  box-sizing: border-box;
  border-radius: 20px;

  &:visited {
    color: inherit;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.gradiant-bar {
  height: 20px;
  background: linear-gradient(
    180deg,
    $grayVlight 0%,
    rgba(255, 255, 255, 0) 100%
  );
  mix-blend-mode: multiply;
  position: relative;
  z-index: 80;
}

.processing {
  background-color: $grayLight !important;
  border-color: $grayLight !important;
  color: $grayDark !important;
}
.processing:hover {
  cursor: default !important;
  background-color: $grayLight !important;
}

.confirm-btn {
  outline: 2px dotted $red;
}

.swal2-image {
  margin-top: 3em;
}

.swal2-actions {
  padding-bottom: 1.5em;
}

.swal2-popup {
  position: relative;
}
button {
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.029em;
  text-align: center;
}

.swal2-popup button.swal2-styled.swal2-confirm {
  align-items: center;
  display: flex;
  background: $yellowLight;
  border: none;
  border-radius: 20px;
  color: $black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.029em;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  width: 220px;

  @include media-breakpoint-down(sm) {
    flex: 1;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: unset;
}

.swal2-confirm {
  font-weight: 700;
}

.swal2-actions.swal2-loading {
  display: none;
  /*right: 186px;
  position: absolute;
  top: 49px;*/
}

.swal2-loader {
  border-color: $white transparent $white transparent;
  height: 1.8em;
  width: 1.8em;
}

.swal2-styled.swal2-confirm:focus {
  background-color: $yellowHover;
  box-shadow: 0 0 0 3px rgba(240, 171, 54, 0.3);
}
@include media-breakpoint-up(md) {
  .bg-container {
    left: 0;
    position: relative;
  }
}

@include media-breakpoint-down(sm) {
  .bg-container .bg-orange {
    background-color: $white;
    height: auto;
    padding-top: 1.2em;
  }

  .bg-container .bg-orange .svg-container {
    display: none !important;
  }

  .bg-container h1 {
    font-size: 1.8rem;
  }
}

@include media-breakpoint-up(md) {
  .bg-container .bg-orange {
    background-color: $orangeFaint;
    background-image: url("./assets/images/orange@1x.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 1200px;
    height: 380px;
    padding-bottom: 70px;
    padding-top: 40px;
    position: relative;
    width: 100%;
  }
}
@include media-breakpoint-down(md) {
  .bg-container .bg-orange {
    background-color: $orangeFaint;
    background-image: url("./assets/images/orange@1x.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 1200px;
    height: 380px;
    padding-bottom: 70px;
    padding-top: 25px;
    width: 100%;
    position: relative;
  }
}
.svg-container {
  bottom: -8px;
  position: absolute;
  width: 100%;
}

svg polygon {
  fill: $white;
}

.bg-container {
  width: 100%;
  background-color: $bgColor;
  text-align: center;
  z-index: -10;

  height: 100px;

  h1,
  h2 {
    margin: auto;
    max-width: calc(100% - 2em);
    position: relative;
    z-index: 20;

    @include media-breakpoint-up(md) {
      max-width: calc(100% - 4em);
    }
  }

  h1 {
    padding-top: 1em;
  }

  h2 {
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 0.35em;
    // padding-bottom: 1.5em;
    padding-bottom: 1em;
  }
}
.form-header {
  font-size: 28px;
  font-weight: 450;
  line-height: 1.5;
  letter-spacing: -0.011em;
  text-align: left;
  text-align: center;
  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}
.form-header-sub {
  font-size: 22px;
  font-weight: 450;
  line-height: 43px;
  letter-spacing: -0.011em;
  text-align: left;
  text-align: center;
}
.section-header {
  font-size: 18px;
  font-weight: 700;
}
.center {
  position: relative;
  @include media-breakpoint-up(sm) {
    margin-top: -300px;
  }
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print-only,
  .print-only * {
    display: block !important;
  }
}
.loader {
  width: 150px;
  height: 150px;
  text-align: center;
  vertical-align: middle;
}
.loader-container {
  text-align: center;
  vertical-align: middle;
}
.err-container {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: inherit;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  border: solid 1px $red;
  border-radius: 3px;
}

.italic {
  font-style: italic;
}
</style>

<template>
    <input
        type="text"
        placeholder="MM/DD/YYYY"
        maxlength="10"
        :value="modelValue"
        @keydown="validateKeydown"
        @blur="formatInput"
    />
</template>

<script>
export default ({
    name: 'DateInput',
    emits: ['update:modelValue', 'blur'],
    props: 
        ["modelValue"],
    data () {
        return {
            raw: "",
            formatted: ""
        }
    },
    computed: {
        cmdKey: {
            get () { 
                if (this.getBrowser() == "firefox") {
                    return 224
                }
                // chrome safari
                return 91
            }
        }
    },
    methods: {
        getBrowser() {
            let agent = navigator.userAgent
            if (agent.match("/chrome|chromium|crios/i")) return "chrome"
            if (agent.match("/firefox|fxios/i")) return "firefox"
            if (agent.match("/safari/i")) return "safari"
            if (agent.match("/edg/i")) return "edge"
        },
        validateKeydown(e) {
            var key = e.key // event.key press
            // if non-number prevent typing
            if (!(!key || key == 'Backspace' || key == 'ArrowLeft' || key == 'ArrowRight' || key == 'Tab' || key == 'Delete' || e.keycode == this.cmdKey || e.ctrlKey)) {
                if (key.match(`[^0-9cv/]+`)) {
                    e.preventDefault()
                }
            }
        },
        formatInput(e) {
            // regex for date format. If format is not matched, add mask. 
            const regex = /^(1[0-2]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/[0-9]{4}$/
            if (regex.test(e.target.value)) {
                let d = new Date(e.target.value)
                var dateString = ("0"+(d.getMonth()+1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2) + "/" + d.getFullYear();
                this.formatted = dateString
            } else {
                // if not correct format, update modelValue anyway so error can be shown 
                this.formatted = e.target.value
            }
            this.$emit('update:modelValue', this.formatted)
            this.$emit('blur')
            
        },
        wearMask(str) {
            if (str === null || str.length == 0) {
                return ``
            }
            if (str.length > 8) {
                str = str.substring(0,8)
            }
            let formatted = this.addDateSlashes(str)
            return formatted
        },
        removeMask(e) {
            this.raw = e.target.value.replaceAll("/", "")
            this.$emit('update:modelValue', this.raw)
        },
        addDateSlashes(str) {
            if (str.length <= 2) {
                return str
            } 
            let a = str.substring(0,2)
            let b = str.substring(2,4)
            if (str.length <= 4) {
                return `${a}/${b}`
            }
            let c = str.substring(4,8)
            return `${a}/${b}/${c}`
        }
    }
})
</script>

<template>
     <label hidden for="mfa-code"></label>
    <div ref="otp" name="mfa-code" class="otp-container">
        <input
            :class="!error ? 'otp-input' : 'otp-input err'"
            inputmode="numeric"
            type="text"
            v-for="(field, index) in numOfDigits"
            :key="field + index"
            v-model="numOfDigits[index]"
            :autofocus="index === 0"
            maxlength="1"
            :id="`code-input-${index}`"
            :name="`code-input-${index}`"
            @keydown="handleKeyDown($event, index)"
            aria-describedby="ariaDescribe"
        >
    </div>
</template>
<script>
export default {
    name: 'OTPInput',
    emits: ['update'],
    props: {
        digits: Number,
        default:String,
        error: Boolean,
        ariaDescribe: String,
    },
    data() {
      return {
        numOfDigits: [],
      }
    },
    created() {
        if (this.default && this.default.length === this.digits) {
            for (let i = 0; i < this.digits; i++) {
                this.numOfDigits[i] = this.default.charAt(i)
            }
        } else {
            for (let i = 0; i < this.digits; i++) {
                this.numOfDigits[i] = null;
            }
        }
    },
    methods: {
        // check to see if otp code is ready
        readyToSubmit() {
            for (let element of this.numOfDigits) {
                if (element !== null) {
                    this.otpReady = true
                } else {
                    // If not ready, break out of loop and run checks below. 
                    this.otpReady = false
                    break;
                }
            }
            if (this.otpReady) {
                this.$emit('update', this.numOfDigits.join(''), this.otpReady)
            } else {
                this.$emit('update', '', this.otpReady)
            }
        },
        // handle keydown event and focuses on next input element
        handleKeyDown(event, index) {
            if (event.key !== "Tab" && 
                event.key !== "ArrowRight" &&
                event.key !== "ArrowLeft"
            ) {
            event.preventDefault();
            }
            
            if (event.key === "Backspace") {
            this.numOfDigits[index] = null;
            
            // go back an input when deleting
            if (index != 0) {
                this.$refs.otp.children[index-1].focus();
            } 
            this.readyToSubmit() 
            return;
            }

            // add number to digits array
            if ((new RegExp('^([0-9])$')).test(event.key)) {
                    this.numOfDigits[index] = event.key;

                if (index != this.digits - 1) {
                    this.$refs.otp.children[index+1].focus();
                }
            }
            this.readyToSubmit() 
        }  
    }
}
</script>
<style scoped lang="scss">
  @import "./src/scss/_variables.scss";
  @import "./src/scss/_mixins.scss";
  .otp-input {
    max-height: 70px;
    height: 14vw;
    max-width: 60px;
    width: 10vw;
    border: 2px solid $gray;
    border-radius: 3px;
    margin: 9px;
    padding: 14px;
    text-align: center;
    font-size: 45px;
    @include media-breakpoint-down(sm) {
        font-size: 32px;
        padding: 2px;
    }
}
.otp-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-input:focus {
    outline: 3px solid black;
}

.err {
    background-color: $redFaint;
    border: solid 2px $red;
}
</style>
<!-- Path: /accounts-closed -->
<template>
  <div class="page">
    <FullSplash/>
    <div class="center">
      <FormContainer header="Account(s) Unavailable">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">
            <div>
             <img srcset="@/assets/images/stop-watch.png 1x, @/assets/images/stop-watch-2x.png 2x" src="@/assets/images/stop-watch-2x.png" title="Deposits failed"/>
            </div>
            <!-- Application Expired -->
          </div>
          <div class="v-spacer-20"/>
          <div class="failed-msg">
            Your account(s) are no longer accessible. We apologize for any inconvenience this may have caused. Please contact us at (808) 844-4545 or toll-free at (800) 894-5600 for assistance.
          </div>  
          <div class="v-spacer-10"/>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import FormContainer from '@/components/FormContainer.vue'
import FullSplash from '@/components/FullSplash.vue'
import jquery from 'jquery'

export default {
  name: 'AccountsClosed',
  title: 'Accounts Closed',
  components: {
    FormContainer,
    FullSplash
},
  data() {
    return {
    }
  },
  mounted() {
    jquery('html, body').animate({ scrollTop: 0 })
  },
  methods: {  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn,.enroll-btn {
  font-size: 1em;
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: none;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover, :active {
    background-color: $yellowHover;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
  font-size: 20px;
  font-weight: 450;
  line-height: 36px;
  letter-spacing: -0.011em;
  text-align: center;
}
.close-container {
  text-align: center;
  margin-top: 60px;
}
.form-header {
  font-weight: 700;
}
.page {
  background-color: $orangeFocus;
}
.enroll-btn {
  color: $white;
  background-color: $red;
  &:hover, :active {
    background-color: $redHover;
  }
}
</style>
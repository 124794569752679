<!-- Path: /welcome-back -->
<template>
  <div class="page">
    <Splash/>
    <div class="center">
      <FormContainer header="Welcome Back">
        <!-- use slot -->
        <template #form-content>

          <div class="form-header">
            <div>
             <img srcset="@/assets/images/account_created.png 1x, @/assets/images/account_created-2x.png 2x" src="@/assets/images/account_created.png" title="Accounts created"/>
            </div>
            Welcome Back {{ customer_data.first_name }} {{ customer_data.last_name }}!
          </div>
          <div class="message">
            Congratulations on your new {{ customer_data.opened_accounts.length > 1 ? 'accounts' : 'account'}}!
            <div>
              Please let us know how you'd like to fund your new accounts.
            </div>
          </div> 
          <!-- mock for loop to display accounts -->
          <div class="grid-table table-spacing">
            <div id="headers">
              PRODUCT & ACCOUNT INFO
            </div>
            <!-- <div id="headers">
              DEPOSIT AMOUNT
            </div> -->
          </div>
          <div v-for="(account, index) in customer_data.opened_accounts" :key="index" :class="!account.is_cd ?  'grid-table': ''" >
            <div v-if="!account.is_cd" class="left-col">
                {{ formatProductType(account.product_type) }} {{ account.account_number }}
                <CheckingDetails v-if="account.account_type == 'dda'" :account="account"/>
                <SavingsDetails v-if="account.account_type == 'sav'"  :account="account"/>
            </div>
            <!-- <div v-if="!account.is_cd" class="right-col">
              {{ account.deposit_amount ? account.deposit_amount : '$0'}}
            </div> -->
            <!-- CD opened accounts need to display more information -->
            <div v-if='account.is_cd' class="cd-info">
              <div class="grid-table" style="border-bottom: none;">
                <div class="left-col">
                  {{ formatProductType(account.product_type) }} {{ account.account_number }}
                  <div class="tf-note">
                    Term: {{ account.cd_term }} {{ account.term_indicator == 'M' ? 'months' : 'years' }},
                    Interest Rate: {{ account.interest_rate }}%,
                    APY: {{ parseFloat(account.apy).toFixed(2) }}%
                  </div>
                </div>
                <!-- <div class="right-col">
                  {{ account.deposit_amount ? account.deposit_amount : '$0'}}
                </div> -->
              </div>
            </div>
            <!-- cd details -->
            <CDDetailsContainer v-if='account.is_cd' :account="account" :toggleDetails="toggle_cd_desc[index]" @click="toggleCDInfo(index)" :isLast="index == customer_data.opened_accounts.length - 1"/>
          </div>
          <div class="v-spacer-40"/>  
          <div class="button-container">
            <button class="print-btn no-print" @click="printPage" id="print-btn">
                PRINT PAGE
            </button>
            <button class="login-btn no-print" @click="submit">
              CONTINUE
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import Splash from '@/components/Splash.vue'
import env from '@/app/env'
import jquery from 'jquery'
import constants from '@/app/constants'
import helpers from '@/app/helpers'
import CDDetailsContainer from '@/components/CDDetailsContainer.vue'
import CheckingDetails from '@/components/CheckingDetails.vue'
import SavingsDetails from '@/components/SavingsDetails.vue'

export default {
  name: 'WelcomeBack',
  components: {
    FormContainer,
    Splash,
    CDDetailsContainer,
    CheckingDetails,
    SavingsDetails
},
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      loginURL: env.loginURL(),
      toggle_cd_desc: [false]
    }
  },
  mounted( ) {
    jquery('html, body').animate({ scrollTop: 0 })
    
  },
  created() {
  },
  methods: {
    ...mapActions('fundnow', 
    [
      'setFromFHB',
      'setFromOther', 
      'setFromCheck', 
      'setCustomerData', 
      'setTotal', 
      'setTransferFrom', 
      'setFundingID', 
      'setDDAExists', 
      'setHasLinkedExternalAccounts', 
      'setCustomerType', 
      'setDDAExists',
      'setSelectedAccount',
      'setAccountNum', 
      'setAccountType', 
      'setBankSelected'
    ]),

    // navigate user to correct flow based on funding step
    submit() {
      switch (this.customer_data.funding_step) {
        case "":
        case "INITIATED":
          this.hasExternalAccounts = this.customer_data.matched_accounts  ? true : false
          this.$router.push(`/initial-deposit`)  
          break
        case "DEPOSIT_VERIFICATION": 
          this.setDepositVerficationData(this.customer_data)
          this.$router.push(`/external/verify-deposits`)  
          break
        case "EXTN_VERIFIED": 
          // need to add condition here to determin which confirm account to go to 
          if (this.customer_data.funding_method_meta === 'EXTERNAL_REALTIME') {
            this.$router.push(`/external/realtime-confirm-account`)
          } else if (this.customer_data.funding_method_meta === 'EXTERNAL_TRIAL') {
            this.setDepositVerficationData(this.customer_data)
            this.$router.push(`external/verify-external-account`)
          }
          break
        case "FUNDING_CHECK": 
          this.$router.push(`/check/fund-by-check`)  
          break
        case "FUNDING_COMPLETED": 
          this.setFundingCompleteData(this.customer_data, this.customer_data.funding_type)
          if (this.customer_data.funding_type == 'INTERNAL') this.$router.push(`/internal/transfer-initiated`)  
          else if (this.customer_data.funding_type == 'EXTERNAL') {
            if (this.customer_data.funding_method_meta === 'EXTERNAL_REALTIME') {
              this.$router.push(`/external/realtime-successful`)
            } else if (this.customer_data.funding_method_meta === 'EXTERNAL_TRIAL') {
              this.$router.push(`/external/verification-successful`)
            }
          }
          else console.log("unexpected funding_step and funding_type: ", this.customer_data.funding_step, this.customer_data.funding_type)
          break
        default: 
          this.$router.push(`/initial-deposit`) 
      }
    },
    setFundingCompleteData(data, fundingType) {
        if (fundingType == 'INTERNAL') {
          this.setSelectedAccount({
            description: data.internal_acc_desc,
            account_number: data.internal_acc,
            account_type: data.internal_acc_type
          })
        } else if (fundingType == 'EXTERNAL') {
          let data = this.customer_data
          data.external_accounts = [{
            account_number: data.extn_acc,
            fi_name: data.extn_fi_name
          }]
          this.customer_data = data
          this.setExtSelectedAccount = data.extn_product
        }
      },

      setDepositVerficationData(data) {
        this.external_bank = data.extn_fi_name
        this.external_account_type = data.extn_product
        this.external_account_number = data.extn_acc
      },
    goBack() {
      this.$router.push('/')
    },
    printPage() {
      window.print()    
    },
    toggleCDInfo(i) {
        this.toggle_cd_desc[i] = !this.toggle_cd_desc[i]
    },
    formatProductType(product_type) {
      return constants.ProductTypeDictionary[product_type]
    },
    moneyToString(val) {
      return helpers.formatMoneyToString(val)
    }
  },
  computed: {
    ...mapGetters('fundnow', [
      'getFromFHB', 
      'getFromOther', 
      'getFromCheck', 
      'getFundingID', 
      'getCustomerData', 
      'getTotal', 
      'getTransferFrom', 
      'getHasLinkedExternalAccounts', 
      'getAccountNum', 
      'getAccountType', 
      'getBankSelected'
    ]),
    total: {
      get() { return this.getTotal }
    },
    customer_data: {
      get() { return this.getCustomerData},
      set(v) { this.setCustomerData(v) }
    },
    funding_id: {
      get() { return this.getFundingID },
      set(v){ this.setFundingID(v)}
    },

    has_external: {
      get() { return this.getHasLinkedExternalAccounts},
      set(v) { this.setHasLinkedExternalAccounts(v) }
    },
    external_account_number: {
      get() { return this.getAccountNum },
      set(v) { this.setAccountNum(v) }
    },
    external_account_type: {
      get() { return this.getAccountType},
      set(v) { this.setAccountType(v) }
    },
    external_bank: {
      get() { return this.getBankSelected },
      set(v) { this.setBankSelected(v) }
    },
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.grid-table {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 15px;
  border-bottom: solid 1px $gray;
  
  #headers {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  .left-col {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;

  }
  .right-col {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
}

.total-grid {
  border: none;
  border-top: 2px solid $gray;
  margin-top: 30px;
}

.table-spacing {
  margin-top: 20px
}
.button-container {
  margin: 30px 0;
  button {
    display: inline-block;
    margin: 5px 20px;
  }
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: 1px solid $yellowLight;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover, :active {
    background-color: $yellowHover;
  }
}
.cd-info {
  font-weight: normal;
  font-size: 15px;
}
.print-btn {
  width: 270px;
  height: max-content;
  border-radius: 999px;
  padding: 8px 24px;
  border: 1px solid $gray;
  background-color: $white;
  cursor: pointer;
  &:hover, :active {
    background-color: $grayVlight;
  }
}
.message {
  text-align: center;
  font-size: 22px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.cd {
  padding: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left;
  border: solid 1px $grayLight;
}
.cert-of-deposit {
  display: block;
  width: 100%;
  border: solid 2px $grayLight;
  padding: 20px 30px;
  margin: 0px 15px;

  div {
    margin: 10px 0;
  }
}
.caret {
  height: 12px;
  width: 18px;
  margin-right: 5px;

  &:hover, :active {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import InitialDeposit from '../views/InitialDeposit.vue'
import internalRoutes from './internal/internal_routes'
import externalRoutes from './external/external_routes'
import checkRoutes from './check/check_routes'
import OpenAccount from '@/views/OpenAccount.vue'
import AccountsFundedManually from '@/views/AccountsFundedManually.vue'
import Driver from '@/views/Testing/driver.vue'
import ExistingCustomerLogin from '@/views/ExistingCustomerLogin.vue'
import returnRoutes from './returnToApp/return_routes'
import enrollmentRoutes from './enrollment/enrollment_routes'
import SessionExpired from '@/views/ErrorPages/SessionExpired.vue'
import errorRoutes from './general errors/errors'


let routes = [
  {
    path: '/',
  },
  {
    path: '/accounts-funded-manually',
    name: 'Accounts-Funded-Manually',
    title: 'Accounts Funded Manually',
    component: AccountsFundedManually
  },
  {
    path: '/open-account/',
    name: 'Fund Now Home',
    component: OpenAccount,
    alias: ['/']
  },
  {
    path: '/initial-deposit',
    name: 'Fund initial deposit',
    component: InitialDeposit,

  },
  {
    path: '/test-driver',
    name: 'Test Driver',
    component: Driver,
    beforeEnter: (to, from, next) => {
      if (window.location.href.indexOf('dev.') > -1) {
        next()
    } else if (window.location.href.indexOf('staging.') > -1) {
      next()
    } else if (window.location.href.indexOf('localhost') > -1) {
      next()
    } else {
      next({
        path: '/'
      })
    }
  }
  },
  {
    path: '/existing-login',
    name: 'Login to Online Banking',
    title: 'Login to Online Banking',
    component: ExistingCustomerLogin
  },
  {
    path: '/session-expired',
    name: 'Session Expired',
    title: 'Session Expired',
    component: SessionExpired
  },
]

routes.push(internalRoutes.loadRoutes(routes))
routes.push(externalRoutes.loadRoutes(routes))
routes.push(checkRoutes.loadRoutes(routes))
routes.push(returnRoutes.loadRoutes(routes))
routes.push(enrollmentRoutes.loadRoutes(routes))
routes.push(errorRoutes.loadRoutes(routes))

// Not found route
routes.push({
  path: '/:pathMatch(.*)*',
  name: 'Page Not Found',
  component: () => import('../views/PageNotFound.vue')
})



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

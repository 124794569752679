<template>
    <input
        type="text"
        placeholder="$0"
        maxlength="12"
        :value="modelValue"
        @keydown="validateKeydown"
        @blur="formatInput"
        @focus="removeMask"
    />
  </template>
  
  <script>
  export default ({
    name: 'MoneyInput',
    props: {
        "modelValue": String    
    }, 
    data () {
        return {
            hasDecimal: false,
            maxCents: false,
            element: null,
            formatted: "",
            raw: ""
        }
    },
    computed: {
        cmdKey: {
            get () { 
                if (this.getBrowser() == "firefox") {
                    return 224
                }
                return 91
            }
        }
    },
    methods: {
        getBrowser() {
            let agent = navigator.userAgent
            if (agent) {
                if (agent.match("/chrome|chromium|crios/i")) return "chrome"
                if (agent.match("/firefox|fxios/i")) return "firefox"
                if (agent.match("/safari/i")) return "safari"
                if (agent.match("/edg/i")) return "edge"
            } else return ""
        },
        validateKeydown(e) {
            var key = e.key // event.key press
            // if non-number prevent typing
            this.hasDecimal = e.target.value.includes('.')
            if (!(key == 'Backspace' || key == 'ArrowLeft' || key == 'ArrowRight' || key == 'Tab' || key == 'Delete' || e.keycode == this.cmdKey || e.ctrlKey)) {
                if (this.hasDecimal) {
                    if (key && key.match(`[^0-9]+`)) {
                        e.preventDefault()
                    } else if (key && key.match(`[0-9]+`) && this.maxDecimalReached(e.target.value)) {
                        e.preventDefault()
                    }
                } else {
                    if (key && key.match(`[^0-9.]+`)) {
                        e.preventDefault()
                    } else if (key && key.match(`[0-9.]+`) && this.maxDecimalReached(e.target.value)) {
                        e.preventDefault()
                    }
                }
            } 
        },
        formatInput(e) {
            // format number
            this.formatted = this.wearMask(e.target.value.replace("$", "").replaceAll(",", ""))
            this.$emit('update:modelValue', this.formatted)
        },
        wearMask(str) {
            if (str === null || str.length == 0) {
                return ""
            }
            str = String(+str)
            if (str == "") return str
            str = this.decimalize(str)
            let num = this.seperateByThousandths(str, ",")
            
            let formatted = `$${num}`
            return formatted
        },
        removeMask(e) {
            this.raw = e.target.value.replace("$", "").replaceAll(",", "")
            this.$emit('update:modelValue', this.raw)
        },
        decimalize(str) {
            if (str.includes('.')) {
                let ind = str.indexOf('.')
                if (ind == 0) {
                    str = `0${str}`
                    ind += 1
                }
                let cents = str.substring(ind+1, ind+3)
                str = `${str.substring(0,ind)}.${cents}`
                if (ind+2 == str.length) { // if only 1 decimal pt, then add a 0 to make 123.1 into 123.10
                    str += "0";
                }
                this.hasDecimal = true
            } else {
                this.hasDecimal = true
                str = `${str}.00`
            }
            this.maxCents = this.maxDecimalReached(str)
            return str
        },
        seperateByThousandths(str, delim) {
            return str.replace(/\B(?=(\d{3})+(?!\d))/g, `${delim}`)
        },
        maxDecimalReached(str) {
            if (!this.hasDecimal) {
                return false
            }
            let money = str.split('.')
            return (money[1].length >= 2) 
        }
    }
  })
  </script>

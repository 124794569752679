<!-- /external/verify-external-account -->
<template>
  <div class="page">
    <Splash />
    <div class="center">
      <FormContainer header="Account Verified">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">
            Transfer from my account at another bank
          </div>
          <div class="v-spacer-30" />
          <div class="info-container">
            <div id="subheader">
              Confirm the information below to start funding
            </div>
            <div class="v-spacer-20" />
            <table>
                <tr>
                  <td>
                    <strong>
                     Transfer amount:
                    </strong>
                  </td>
                  <td>
                    {{ formattedTotal }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Bank Name:
                    </strong>
                  </td>
                  <td>
                    {{ external_bank }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      From Account:
                    </strong>
                  </td>
                  <td>
                    <span>{{ }}</span>
                    <span>{{ external_account_number }}</span>
                  </td>
                </tr>
            </table>
          </div>
          <div class="v-spacer-50"> 
            <div class="error-container" v-if="respErr">
              <div class="image-container">
                <img srcset="@/assets/images/error-msg-icon.png 1x, @/assets/images/error-msg-icon-2x.png 2x" src="@/assets/images/error-msg-icon.png" title="error message"/> 
              </div>
              <div class="error-message" role="alert">
                {{ errMsg }}
              </div>
            </div> 
          </div>  
          <div class="button-container">
            <button :class="processing ? 'processing-button' : 'next-button no-print'"  @click="submitPage">
              {{ processing ? 'PROCESSING...' : 'CONTINUE' }}
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormContainer from "@/components/FormContainer.vue";
import Splash from "@/components/Splash.vue";
import jquery from "jquery";
import funding_api from "@/app/funding_api";
import helpers from '@/app/helpers';

export default {
  name: "ExternalFundingDeposits",
  components: {
    FormContainer,
    Splash,
  },
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      missingRequest: false,
      chosenAccount: "",
      respErr: false,
      errMsg: 'We were unable to link your account due to a system error. Please try again.'
    };
  },
  created() {
  },
  mounted() {
    jquery("html, body").animate({ scrollTop: 0 });
    // console.log(this.customer_data.external_accounts)
  },
  methods: {
    ...mapActions("fundnow", [
      "setCustomerData",
      "setAccounts",
      "setExtSelectedAccount",
      "setCustomerAccounts",
      "setRealtimeVerifyResp",
      "setJwtToken",
      "setEnrollmentSSO"
    ]),

    async submitPage() {
      this.postExternalFunding()
    },
    async postExternalFunding() {
      this.respErr = false
      this.processing = true
      const resp = await funding_api.postExternalFunding(this.app_id, this.jwtToken)
      this.processing = false
      if (resp.status == 200) {
        // updated funded_at
        for (let i = 0; i < this.customer_data.opened_accounts.length; i++) {
          for (let j = 0; j < resp.data.response.accounts.length; j++) {
            if (this.customer_data.opened_accounts[i].account_number == resp.data.response.accounts[j].account_number) {
              this.customer_data.opened_accounts[i].funded_at = resp.data.response.accounts[j].funded_at
            }
          }
        }
        if (resp.data?.response?.enrollment_sso) {
          this.setEnrollmentSSO(resp.data.response.enrollment_sso)
        }
        this.$router.push('/external/verification-successful')
      } else if (resp.status == 202) {
        // funding fails. Need to pass flow type as a query param to redirect to success page.
        this.$router.push({
          name: 'Funding Failure (Retry)',
          query: {flow: "TRIAL"}
        })
      } else if (resp.status == 502) {
        // timeout
        this.$router.push('/external/transfer-timeout')
      } else if (resp.status == 500){
        this.respErr = true
      } else {
        // any other errors
        this.$router.push("/external/something-went-wrong");
      }
    },
    ifWholeNumber(v) {
      try {
        if (v.includes('.')) {
          return v
        }
        return helpers.formatMoneyToString(helpers.formatMoneyFromString(v))
      } catch {
        return helpers.formatMoneyToString(v)
      }
    }
  },
  computed: {
    ...mapGetters("fundnow", [
      "getCustomerData",
      "getAccounts",
      "getExtSelectedAccount",
      "getCustomerAccounts",
      "getTotal",
      "getAccountType",
      "getRoutingNum",
      "getAccountNum",
      "getAccountNickname",
      "getRealtimeVerifyResp",
      'getFundingID',
      'getJwtToken',
      'getAccountNum',
      'getAccoutnType',
      'getBankSelected'
    ]),
    customer_data: {
      get() {
        return this.getCustomerData;
      },
      set(v) {
        this.setCustomerData(v);
      },
    },
    total: {
      get() {
        return helpers.formatMoneyFromString(this.getTotal)
      },
    },
    formattedTotal: {
      get() {
        return helpers.formatMoneyToString(this.total)
      }
    },
    app_id: {
      get() { return this.getFundingID},
    },
    jwtToken: {
      get() { return this.getJwtToken},
      set(v){ this.setJwtToken(v) }
    },
    external_account_number: {
      get() { return this.getAccountNum }
    },
    external_account_type: {
      get() { return this.getAccountType }
    },
    external_bank: {
      get() { return this.getBankSelected }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.form-content-container {
  margin-left: 20px;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
input[type="radio"] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
}

.radio-line {
  // display: flex;
  margin: 30px 15px;
  input,
  label,
  div {
    display: inline-block;
    justify-self: center;
    vertical-align: middle;
  }
}
.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.available-balance {
  color: $gray;
}
.info-container {
  div {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.011em;
    text-align: left;
  }
  table {
    padding: 10px;
    border-collapse: collapse;
    border-spacing: 12em;
    width: 1200px;
    height: min-content;
    table-layout: fixed;
    font-size: 1rem;

    width: 100%;
  }
  td {
      padding: 10px;
  }
  tr {
      border-bottom: solid 1px $gray;
  }
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}
.error-container {
  height: 50px;
  border: solid 1px $red;
  padding: 10px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  text-align: left;
}
.image-container {
  width: 40px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $redLight;
  img {
    width: 18px;
    height: 18px;
  }
}
.error-message {
  padding-left: 15px;
  color: $red
}
</style>

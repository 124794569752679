<!-- Path: /external/deposits-failed -->
<template>
  <div class="page">
    <FullSplash/>
    <div class="center">
      <FormContainer header="Verify Your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">
            <div>
             <img srcset="@/assets/images/stop-watch.png 1x, @/assets/images/stop-watch-2x.png 2x" src="@/assets/images/stop-watch-2x.png" title="Deposits failed"/>
            </div>
            Application Expired
          </div>
         
          <div class="v-spacer-20"/>
          <div class="failed-msg">
            For your security, your application has expired due to inactivity. Please select “Return to Application” to let us know how you’d like to fund your new account(s)
          </div>  
          <div class="v-spacer-10"/>
          <div class="button-container">
            <button class="login-btn" @click="goToExternalMethods">
              RETURN TO APPLICATION
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import FullSplash from '@/components/FullSplash.vue'

import jquery from 'jquery'

export default {
  name: 'DepositsNotSent',
  components: {
    FormContainer,
    FullSplash
},
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      account: '',
    }
  },
  mounted() {
    jquery('html, body').animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions('fundnow', ['resetAll',]),

    goToExternalMethods() {
      this.$router.push(`/return/access-application/${this.funding_id}`)
      // clear app data after redirect
      this.resetAll()
    },
    goBack() {
      this.$router.push('/')
    },
  },
  computed: {
    ...mapGetters('fundnow', ['getFundingID']),
    funding_id: {
      get() {return this.getFundingID }
    }
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: none;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover, :active {
    background-color: $yellowHover;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
font-size: 20px;
font-weight: 450;
line-height: 36px;
letter-spacing: -0.011em;
text-align: center;

}
.close-container {
  text-align: center;
  margin-top: 60px;
}
.form-header {
  font-weight: 700;
}
.page {
  background-color: $orangeFocus;
}
</style>

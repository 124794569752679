<!-- Path: /external/realtime-successful -->
<template>
  <div class="page">
    <Splash/>
    <div class="center">
      <FormContainer header="Congratulations">
        <!-- use slot -->
        <template #form-content>

          <div class="form-header">
            <div>
             <img srcset="@/assets/images/account_created.png 1x, @/assets/images/account_created-2x.png 2x" src="@/assets/images/account_created.png" title="Accounts created"/>
            </div>
            {{ customer_data?.opened_accounts?.length > 1 ? 'Accounts' : 'Account' }} Opened
          </div>
         
          <div class="v-spacer-20"/>
          <div>
            Your {{ customer_data?.opened_accounts?.length > 1 ? 'accounts are' : 'account is' }} now open and can be accessed via FHB Online & Mobile Banking. in the next 1-3 business days, your initial deposit of {{ formattedTotal }} will be transferred from your {{ customer_data.selected_external_account.fi_name }} x{{ account_num }} account to:
          </div> 

          <!-- mock for loop to display accounts -->
          <div class="grid-table table-spacing">
            <div id="headers">
              PRODUCT & ACCOUNT INFO
            </div>
            <div id="headers">
              DEPOSIT AMOUNT
            </div>
          </div>
          <div v-for="(account, index) in  customer_data?.opened_accounts" :key="index" :class="[!account.is_cd ?  'grid-table': '', index == customer_data?.opened_accounts.length - 1 ? 'no-bottom-border' : '']">
            <div class="left-col" v-if='!account.is_cd'>
                {{ formatProductType(account.product_type) }} {{ account.account_number }}
                <CheckingDetails v-if="account.account_type == 'dda'" :account="account"/>
                <SavingsDetails v-if="account.account_type == 'sav'" :account="account"/>
            </div>
            <div class="right-col" v-if='!account.is_cd'>
              {{ ifWholeNumber(account.deposit_amount) }}
            </div>
            <!-- CD opened accounts need to display more information -->
            <div v-if='account.is_cd' class="cd-info">
              <div class="grid-table no-bottom-border">
                <div class="left-col">
                  {{ formatProductType(account.product_type) }} {{ account.account_number }}
                </div>
                <div class="right-col">
                    {{ ifWholeNumber(account.deposit_amount) }}
                </div>
              </div>
            </div>
              <!-- cd details -->
              <CDDetailsContainer v-if='account.is_cd' :account="account" :toggleDetails="toggle_cd_desc[index]" @click="toggleCDInfo(index)" :isLast="index == customer_data.opened_accounts.length - 1"/>
          </div>
           <!-- total -->
           <div class="grid-table-total" id="total" v-if="customer_data?.opened_accounts?.length > 1">
            <div class="left-col">
                <i style="font-weight: normal;">Total Deposit Amount</i>
            </div>
            <div class="right-col">
              {{ formattedTotal }}
            </div>
          </div>
          <div class="v-spacer-40"/>
          <div class="button-container">
            <button class="print-btn no-print" @click="printPage" id="print-btn">
                PRINT PAGE
            </button>
            <button class="login-btn no-print" @click="submit">
             CONTINUE TO FHB ONLINE
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import Splash from '@/components/Splash.vue'
import env from '@/app/env'
import jquery from 'jquery'
import helpers from '@/app/helpers'
import constants from '@/app/constants'
import CDDetailsContainer from '@/components/CDDetailsContainer.vue'
import CheckingDetails from '@/components/CheckingDetails.vue'
import SavingsDetails from '@/components/SavingsDetails.vue'

export default {
  name: 'RealtimeSuccessful',
  components: {
    FormContainer,
    Splash,
    CDDetailsContainer,
    CheckingDetails,
    SavingsDetails
},
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      account: '',
      loginURL: env.enrolledLoginURL(),
      enrollURL: env.enrollmentURL(),
      toggle_cd_desc: [false]
    }
  },
  mounted( ) {
    console.log(this.selected_account)
    jquery('html, body').animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions('fundnow', []),

    submit() {
      // go to retail enrollment
      if (this.getEnrollmentSSO) {
        window.open(this.getEnrollmentSSO, '_blank')
      } else {
        window.open(this.loginURL, '_blank' )
      }
    },
    goBack() {
      this.$router.push('/')
    },
    printPage() {
      window.print()    
    },
    toggleCDInfo(i) {
      this.toggle_cd_desc[i] = !this.toggle_cd_desc[i]
    },
    ifWholeNumber(v) {
      try {
        if (v.includes('.')) {
          return v
        }
        return helpers.formatMoneyToString(helpers.formatMoneyFromString(v))
      } catch {
        return helpers.formatMoneyToString(v)
      }
    },
    formatProductType(product_type) {
      return constants.ProductTypeDictionary[product_type]
    },
  },
  computed: {
    ...mapGetters('fundnow', ['getExtSelectedAccount', 'getCustomerData', 'getTotal', 'getEnrollmentSSO']),
    selected_account: {
      get() { return this.getExtSelectedAccount },
    },
    customer_data: {
      get() { return this.getCustomerData},
    },
    account_num: {
      get() { 
        let accNum = this.customer_data?.external_accounts ? this.customer_data.selected_external_account?.account_number?.slice(-4) : ''
        return accNum
      }
    },
    total: {
      get() {
        return helpers.formatMoneyFromString(this.getTotal);
      },
    },
    formattedTotal: {
      get() {
        return helpers.formatMoneyToString(this.total);
      },
    },
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.grid-table {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 15px;
  border-bottom: solid 1px $gray;
  
  #headers {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  .left-col {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;

  }
  .right-col {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
  @include media-breakpoint-down(sm) {
    display: block;
    .right-col {
      font-weight: 700;
    }
  }
}
.grid-table-total {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 15px;
  border-top: 2px solid $gray;
  margin-top: 15px;
  
  #headers {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  .left-col {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;

  }
  .right-col {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
  @include media-breakpoint-down(sm) {
    display: block;
    .right-col {
      font-weight: 700;
    }
  }
}
.table-spacing {
  margin-top: 20px
}
.button-container {
  text-align: center;
  margin: 30px 0;
  button {
    display: inline-block;
    margin: 5px 20px;
  }
  @include media-breakpoint-down(sm) {
    button {
    margin: 5px 0px;
    }
  } 
  
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  max-width: 274px;
  width: 100%;
  height: 42px;
  border-radius: 999px;
  padding: 4px 24px;
  border: 1px solid $yellowLight;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover, :active {
    background-color: $yellowHover;
  }
  @include media-breakpoint-down(sm) {
    height: max-content;
  } 
}
.cd-info {
  font-weight: normal;
  font-size: 15px;
}
.print-btn {
  max-width: 270px;
  width: 100%;
  height: max-content;
  border-radius: 999px;
  padding: 8px 24px;
  border: 1px solid $gray;
  background-color: $white;
  cursor: pointer;
  &:hover, :active {
    background-color: $grayVlight;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.cd {
  padding: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left;
  border: solid 1px $grayLight;
}
.cert-of-deposit {
  display: block;
  width: inherit;
  border: solid 2px $grayLight;
  padding: 20px 30px;
  margin: 0px 15px;

  div {
    margin: 10px 0;
  }
}
  @media print
{    
    .print-only-cd, .print-only-cd * {
      display: grid !important;
    }
}
.caret {
  height: 12px;
  width: 18px;
  margin-right: 5px;

  &:hover, :active {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
}
.border-after-cd {
  margin-top: 15px;
  border: solid 1px $gray;
}
.no-bottom-border {
  border-bottom: none;
}
</style>

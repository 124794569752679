<!-- Path: /view-account -->
<template>
  <div class="page">
    <Splash/>
    <div class="center">
      <FormContainer header="Welcome Back">
        <!-- use slot -->
        <template #form-content>

          <div class="form-header">
            <div>
             <img srcset="@/assets/images/account_created.png 1x, @/assets/images/account_created-2x.png 2x" src="@/assets/images/account_created.png" title="Accounts created"/>
            </div>
            Welcome Back {{ customer_data.first_name }} {{ customer_data.last_name }}!
          </div>
          <div class="message">
            Congratulations on your new account(s)!
          </div> 
          <!-- mock for loop to display accounts -->
          <div class="grid-table table-spacing">
            <div id="headers">
              PRODUCT & ACCOUNT INFO
            </div>
            <div id="headers">
              DEPOSIT AMOUNT
            </div>
          </div>
          <div v-for="(account, index) in customer_data.opened_accounts" :key="index" :class="[!account.is_cd ?  'grid-table': '', index == customer_data?.opened_accounts?.length - 1 ? 'no-bottom-border' : '']" >
            <div v-if="!account.is_cd" class="left-col">
                {{ formatProductType(account.product_type) }} {{ account.account_number }}
                <CheckingDetails v-if="account.account_type == 'dda'" :account="account"/>
                <SavingsDetails v-if="account.account_type == 'sav'"  :account="account"/>
            </div>
            <div v-if="!account.is_cd" class="right-col">
              {{ ifWholeNumber(account.deposit_amount) }}
            </div>
            <!-- CD opened accounts need to display more information -->
            <div v-if='account.is_cd' class="cd-info">
              <div class="grid-table no-bottom-border">
                <div class="left-col">
                  {{ formatProductType(account.product_type) }} {{ account.account_number }}
                </div>
                <div class="right-col">
                  {{ ifWholeNumber(account.deposit_amount) }}
                </div>
              </div>
            </div>
            <!-- cd details -->
            <CDDetailsContainer v-if='account.is_cd' :account="account" :toggleDetails="toggle_cd_desc[index]" @click="toggleCDInfo(index)" :isLast="index == customer_data?.opened_accounts?.length - 1"/>
          </div>
           <!-- total -->
           <div class="grid-table-total" id="total" v-if="customer_data?.opened_accounts?.length > 1">
            <div class="left-col">
                <i style="font-weight: normal;">Total Deposit Amount</i>
            </div>
            <div class="right-col">
              {{formattedTotal }}
            </div>
          </div>
          <div class="v-spacer-40"/>
          <div class="assistance-note">
            If you need additional assistance,
          </div>
          <div class="assistance-note">
            please call us at (808) 844-4545 or toll-free at (800) 894-5600.
          </div>
          <div class="button-container">
            <button class="print-btn no-print" @click="printPage" id="print-btn">
                PRINT PAGE
            </button>
            <button v-if="enrollmentSSO" class="login-button" @click="enrollClicked" id="print-btn">
                Enroll in FHB Online
            </button>
          </div>
          <div class="app-text">
              <div >
                Download the app for FHB Online & Mobile Banking
              </div>
              <div class="mobile-apps">
                <a href="https://apps.apple.com/app/id1172432893" target="_blank"><img class="appleLogo" src="@/assets/images/Apple_Store_Badge@1.5x.png" alt="apple store badge"></a>
                <a href="https://play.google.com/store/apps/details?id=com.firsthawaiian.mobile" target="_blank"><img class="playstoreLogo" src="@/assets/images/google-play-badge@1.5x.png" alt="play store badge"></a>
              </div>
            </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import Splash from '@/components/Splash.vue'
import env from '@/app/env'
import jquery from 'jquery'
import constants from '@/app/constants'
import helpers from '@/app/helpers'
import CDDetailsContainer from '@/components/CDDetailsContainer.vue'
import CheckingDetails from '@/components/CheckingDetails.vue'
import SavingsDetails from '@/components/SavingsDetails.vue'

export default {
  name: 'ViewAccountPage',
  components: {
    FormContainer,
    Splash,
    CDDetailsContainer,
    CheckingDetails,
    SavingsDetails    
},
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      loginURL: env.loginURL(),
      toggle_cd_desc: [false]
    }
  },
  mounted( ) {
    jquery('html, body').animate({ scrollTop: 0 })
    
  },
  created() {
  },
  methods: {
    ...mapActions('fundnow', 
    [
      'setFromFHB',
      'setFromOther', 
      'setFromCheck', 
      'setCustomerData', 
      'setTotal', 
      'setTransferFrom', 
      'setFundingID', 
      'setDDAExists', 
      'setHasLinkedExternalAccounts', 
      'setCustomerType', 
      'setDDAExists',
      'setSelectedAccount',
      'setAccountNum', 
      'setAccountType', 
      'setBankSelected'
    ]),
    enrollClicked() {
      window.open(this.enrollmentSSO, '_blank')
    },
    // navigate user to correct flow based on funding step
    printPage() {
      window.print()    
    },
    toggleCDInfo(i) {
        this.toggle_cd_desc[i] = !this.toggle_cd_desc[i]
    },
    formatProductType(product_type) {
      return constants.ProductTypeDictionary[product_type]
    },
    ifWholeNumber(v) {
      try {
        if (v.includes('.')) {
          return v
        }
        return helpers.formatMoneyToString(helpers.formatMoneyFromString(v))
      } catch {
        return helpers.formatMoneyFromString(v)
      }
    },
  },
  computed: {
    ...mapGetters('fundnow', [
      'getFromFHB', 
      'getFromOther', 
      'getFromCheck', 
      'getFundingID', 
      'getCustomerData', 
      'getTotal', 
      'getTransferFrom', 
      'getHasLinkedExternalAccounts', 
      'getAccountNum', 
      'getAccountType', 
      'getBankSelected',
      'getEnrollmentSSO'
    ]),
    enrollmentSSO: {
      get() { return this.getEnrollmentSSO != '' ? this.getEnrollmentSSO : null }
    },
    customer_data: {
      get() { return this.getCustomerData},
      set(v) { this.setCustomerData(v) }
    },
    funding_id: {
      get() { return this.getFundingID },
      set(v){ this.setFundingID(v)}
    },

    has_external: {
      get() { return this.getHasLinkedExternalAccounts},
      set(v) { this.setHasLinkedExternalAccounts(v) }
    },
    external_account_number: {
      get() { return this.getAccountNum },
      set(v) { this.setAccountNum(v) }
    },
    external_account_type: {
      get() { return this.getAccountType},
      set(v) { this.setAccountType(v) }
    },
    external_bank: {
      get() { return this.getBankSelected },
      set(v) { this.setBankSelected(v) }
    },
    total: {
      get() {
        return helpers.formatMoneyFromString(this.getTotal);
      },
    },
    formattedTotal: {
      get() {
        return helpers.formatMoneyToString(this.total);
      },
    },
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.grid-table {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 15px;
  border-bottom: solid 1px $gray;
  
  #headers {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  .left-col {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;

  }
  .right-col {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
  @include media-breakpoint-down(sm) {
    display: block;
    .right-col {
      font-weight: 700;
    }
  }
}
.table-spacing {
  margin-top: 20px
}
.button-container {
  text-align: center;
  margin: 30px 0;
  button {
    display: inline-block;
    margin: 5px 20px;
  }
  @include media-breakpoint-down(sm) {
    button {
    margin: 5px 0px;
    }
  } 
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  max-width: 274px;
  width: 100%;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: 1px solid $yellowLight;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover, :active {
    background-color: $yellowHover;
  }
  @include media-breakpoint-down(sm) {
    height: max-content;
  } 
}
.cd-info {
  font-weight: normal;
  font-size: 15px;
}
.print-btn {
  max-width: 270px;
  width: 100%;
  height: max-content;
  border-radius: 999px;
  padding: 8px 24px;
  border: 1px solid $gray;
  background-color: $white;
  cursor: pointer;
  &:hover, :active {
    background-color: $grayVlight;
  }
}
.message {
  text-align: center;
  font-size: 20px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.cd {
  padding: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left;
  border: solid 1px $grayLight;
}
.cert-of-deposit {
  display: block;
  width: 100%;
  border: solid 2px $grayLight;
  padding: 20px 30px;
  margin: 0px 15px;

  div {
    margin: 10px 0;
  }
}
.caret {
height: 12px;
width: 18px;
margin-right: 5px;

&:hover, :active {
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
}
.assistance-note {
  text-align: center;
}
.app-text {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  .mobile-apps {
    padding-top: 20px;
  }
}
.appleLogo {
  padding-right: 20px;
  height: 40px;
  width: 134px;
}
.playstoreLogo {
  height: 40px;
  width: 134px;
}
.grid-table-total {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 15px;
  border-top: 2px solid $gray;
  margin-top: 15px;

  #headers {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  .left-col {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;

  }
  .right-col {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
  @include media-breakpoint-down(sm) {
    display: block;
    .right-col {
      font-weight: 700;
    }
  }
}
.border-after-cd {
  margin-top: 15px;
  border: solid 1px $gray;
}
.no-bottom-border {
  border-bottom: none;
}

.login-button, .enroll-button {
  background-color: $yellowLight;
  max-width: 270px;
  width: 100%;
  height: max-content;
  border-radius: 999px;
  padding: 8px 24px;
  border: none;
  text-transform: uppercase;
  font-size: 13px;
  &:hover, :active { 
    cursor: pointer;
    background-color: $yellowHover;
  }
}
</style>

<template>
    <div @click="missingAgreementEvent" class="checkbox-container" :for="this.id">
      <div class="tf-label" v-if="this.label">{{ this.label }}<sup v-if="this.required" class="fhb-red">*</sup></div>
      <div class="checkbox">
        <label :for="this.id">
          <input :required="required" :name="name" type="checkbox" :id="this.id" :data-test="this.dataTest" @click="toggleCB" :value="modelValue" :disabled="link && !modelValue ? notClicked : false" :aria-describedby="ariaDescribe">
          <span> {{ this.statement }} </span>
          <span v-if="linkText"><a id="link" :href="link" target=”_blank” @click="checkClick">{{ this.linkText }}</a></span>
        </label>
      </div>
      <slot name="nickname">
      </slot>
    </div>
    <div class="error" role="alert" :id="ariaDescribe">
        <template v-if="missing" >
            <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png"/>
            <div v-if="missing" id="missing">
            {{error}}
                </div>
        </template>
    </div>
  </template>
  
  <script>  
  export default ({
  
    name: 'LabeledCheckbox',
    emits: ['update:modelValue', 'change'],
    props: {
      modelValue: Boolean, 
      label: String,
      id: String,
      dataTest: String,
      required: Boolean,
      statement: String,
      errorMsg: String,
      linkText: String,
      link: String,
      missing: Boolean,
      error: String,
      name: String, 
      ariaDescribe: String
    },
    data () {
      return {
        notClicked: true
      }
    },
    mounted() {
      if (this.modelValue === true) {
        let cb = document.getElementById(this.id)
        console.log(cb)
        cb.checked = true
      }
    },
    computed: {
    },
    methods: {
      toggleCB(e) {
        let cb = document.getElementById(this.id)
        console.log(cb.checked)
        this.$emit('update:modelValue', (cb.checked == true))
        e.stopPropagation()
        this.$emit('missingAgreementEvent', e, cb.checked != true)
        this.$emit('change')
      },
      checkClick(e) {
        e.stopPropagation()
        this.notClicked = false
        this.$emit('missingAgreementEvent',e, false)
      },
      missingAgreementEvent(e) {
        this.$emit('missingAgreementEvent', e, this.notClicked)
      }
    }
  })
  </script>
  
  <style lang="scss" scoped>
  @import "./src/scss/_variables.scss";
  @import "./src/scss/_mixins.scss";
  .checkbox-container {
    border: solid 2px $grayLight;
    padding: 18px;
    border-radius: 5px;
    &:hover {
        background-color: $grayLight;
        cursor: pointer;
    }
  }
  .checkbox {
    cursor: pointer;
    input {
        height: 22px;
        width: 22px;
        display: inline-block;
        justify-self: center;
        vertical-align: middle;
        margin-right: 10px;
    }
    label {
        justify-self: center;
        vertical-align: middle;
    }
  }
  
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: auto;
  font-size: 15px;
  font-weight: 450;
  line-height: 1.5;
  img {
    width: 15px;
    height: 15px;   
  }
}

  
  </style>
  
const auth = {
    requireAuth(to, from, next, iss, token, redirectPath) {
      if (token == null) {
        console.log(`jwt token was null`)
        next({
          path: redirectPath
        })
      }
      const parts = token.split('.')
      if (parts.length < 3 || parts.length > 3) {
        console.log(`jwt token was malformed`)
        next({
          path: redirectPath
        })
      }
      const claims = JSON.parse(atob(parts[1]))
      const secondsEpoch = Math.floor(Date.now()/1000)
      if (claims.exp < secondsEpoch || claims.iss != iss) {
        console.log(`jwt token is expired or has invalid issuer`)
        next({
          path: redirectPath
        })
      }
      next()
    },
  }
  
  export default auth
  
<!-- Path: /external/account-verification-failed -->
<template>
  <div class="page">
    <Splash/>
    <div class="center">
      <FormContainer header="Something Went Wrong">
        <!-- use slot -->
        <template #form-content>

          <div class="form-header">
            <div>
             <img srcset="@/assets/images/failed-error.png 1x, @/assets/images/failed-error-2x.png 2x" src="@/assets/images/failed-error.png" title="Deposits failed"/>
            </div>
           Account Verification Error
          </div>
         
          <div class="v-spacer-20"/>
          <div class="failed-msg">
            There was an error verifying your external bank account. Please verify your account using the trial deposit method instead.
          </div>  

          <div style="text-align: center; margin-top: 50px;">
            Need help? Call us at (808) 844-4545 or toll free at (800) 894-5600.
          </div>
          <div class="v-spacer-20"/>
          <div class="button-container">
            <button class="login-btn" @click="goToExternalMethods">
              CONTINUE
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import Splash from '@/components/Splash.vue'
import jquery from 'jquery'

export default {
  name: 'AccountVerificationFailed',
  components: {
    FormContainer,
    Splash
},
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      account: '',
    }
  },
  mounted() {
    jquery('html, body').animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions('fundnow', ['setRealTimeVerification']),

    goToExternalMethods() {
      // this.real_time_verification = false
      this.$router.push('/external/trial-deposits-verify')
    },
  },
  computed: {
    ...mapGetters('fundnow', ['getRealtimeVerification']),
    real_time_verification: {
      get() { 
        return this.getRealtimeVerification
      },
      set(v) { this.setRealTimeVerification(v) }
    },
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: none;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover, :active {
    background-color: $yellowHover;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
font-size: 24px;
font-weight: 450;
line-height: 36px;
letter-spacing: -0.011em;
text-align: center;

}
.close-container {
  text-align: center;
  margin-top: 60px;
}
</style>

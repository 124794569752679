<!-- Path: /external/realtime-verify -->
<template>
  <div class="page">
    <SplashBackground/>
    <div class="center">
      <FormContainer header="Verify Your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="info-container">
          <div class="v-spacer-20"/>
            <div class="verify-desc">Please verify {{ realtime_resp.fi_name }} x{{ account_number }} account with these simple steps:</div>
            <div>
                <ol>
                  <li>
                  Login to your {{ accountInfo.bank }} account (immediate)
                  </li>
                  <div class="v-spacer-10" />
                  <li>
                  Give your permission for {{ accountInfo.bank }} to share data with us
                  </li>
                </ol>
            </div>
            <div>
              To get started, click on AUTHENTICATE and you'll be taken to the {{ accountInfo.bank }} website to sign in then select the account you want to transfer from. 
            </div>
            <div class="v-spacer-20"/>       
            <div>
              We will never save your {{ accountInfo.bank }} login information, this is only used to verify your account information.
            </div>       
            <div class="button-container">
              <button :class="submitted ? 'sent-button' : 'next-button'" @click="submit">
                <div v-if="!submitted">
                AUTHENTICATE
                </div>
              </button>
            </div>
            <div class="v-spacer-20"/>      
            <div style="text-align: center; cursor: pointer; text-decoration: underline;" @click="changeToDeposit" v-if="real_time_verification">
                I don't have an online account with {{ realtime_resp.fi_name }}
            </div>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import SplashBackground from '@/components/Splash.vue'
import jquery from 'jquery'

export default {
  name: 'RealtimeInit',
  components: {
    FormContainer,
    SplashBackground,
  },
  data() {
    return {
      missingVerifyMethod: false,
      processing: false,
      next: false,
      submitted: false,
    }
  },
  created() {

  },
  mounted() {
    jquery('html, body').animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions('fundnow', ['setAccountType', 'setRoutingNum', 'setAccountNum', 'setAccountNickname', 'setSaveAccount', 'setReadAgreement', 'setLoginAccount', 'setVerifyAmounts', 'setVerifyMethod', 'setExtSelectedAccount', 'setAccounts', 'setRealTimeVerification', 'setRealtimeResp', 'setJwtToken']),

    submit() {
      this.submitted = true
      this.$router.push('/external/realtime-authentication-login')
    },
    changeToDeposit() {
      this.$router.push('/external/trial-deposits-verify')

    },

  },
  computed: {
    ...mapGetters('fundnow', ['getFundingID', 'getAccountType', 'getRoutingNum', 'getAccountNum', 'getAccountNickname', 'getSaveAccount', 'getReadAgreement', 'getLoginAccount', 'verifyAmounts', 'getVerifyMethod', 'getExtSelectedAccount', 'getAccounts', 'getRealtimeVerification', 'getRealtimeResp', 'getJwtToken']),
    verify_method: {
      get() { return this.getVerifyMethod },
      set(v){ this.setVerifyMethod(v)}
    },
    accountInfo: {
      get() { return this.getExtSelectedAccount}
    },
    account_number: {
      get() { 
        return this.getAccountNum.slice(-4)
      }
    },
    real_time_verification: {
      get() { 
        return this.getRealtimeVerification
      },
      set(v) { this.setRealTimeVerification(v) }
    },
    app_id: {
      get() { return this.getFundingID},
      set(v){ this.setFundingID(v)}
    },
    jwtToken: {
      get() { return this.getJwtToken},
      set(v){ this.setJwtToken(v) }
    },
    realtime_resp: {
      get() { return this.getRealtimeResp},
      set(v){ this.setRealtimeResp(v) }
    }
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.form-content-container {
  margin-left: 20px;
  // display: grid;
  row-gap: 20px;
  margin-top: 10px;
  // grid-template-columns: 50% 50%;
  // @include media-breakpoint-down(sm) {
  //   display: block;
  // }
}
.section-2 {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
}
input[type=radio] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
} 

.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover, :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.infobox {
  max-width: 599.1px;
  padding: 10px 10px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  letter-spacing: -0.011em;
  text-align: left;
  border: 2px solid $grayLight;
  margin-left: 60px;
}
.info-icon {
  width: 15px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover, :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.account-nickname-container {
  padding: 10px 20px;
}
.radio-line {
  display: flex;
  margin: 15px
}
.section-header-2 {
  text-align: left;
}
.table-header {
  font-weight: 700;
  color: $grayDark;
}
table {
  width: 100%;
  text-align: left;
  tr {
    border-bottom: solid 1px $gray;
  }
  td, th {
    padding: 5px 10px;
    // font-size: 12px;
    // font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.029em;


  }
}
.note {
  background-color: $orangeFaint;
  padding: 18px;
}
.sent-button {
  color: black;
  background-color: $yellowHover;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
}
.verify-desc {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left;
}
.info-container {
  padding: 0px 10px;
}
.error-container {
  height: 50px;
  border: solid 1px $red;
  padding: 10px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  text-align: left;
}
.image-container {
  width: 40px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $redLight;
  img {
    width: 18px;
    height: 18px;
  }
}
.error-message {
  padding-left: 15px;
  color: $red
}
.sent-img {
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);

}
</style>

<!-- Path: /external/funding-failed -->
<template>
  <div class="page">
    <Splash/>
    <div class="center">
      <FormContainer header="Something Went Wrong">
        <!-- use slot -->
        <template #form-content>

          <div class="form-header">
            <div>
             <img srcset="@/assets/images/failed-error-red.png 1x, @/assets/images/failed-error-red-2x.png 2x" src="@/assets/images/failed-error-red.png" title="funding_ failed"/>
            </div>
           Verification Unsuccessful  
          </div>
         
          <div class="v-spacer-20"/>
          <div class="failed-msg" v-if="customer_data.selected_external_account.fi_name">
            We were unable to verify your {{ customer_data.selected_external_account.fi_name }} {{ customer_data.selected_external_account.account_name}} x{{ customer_data.selected_external_account.account_number }} account.
            We apologize for the inconvenience.
            <div class="v-spacer-10"/>
            <div>
              Please deposit a check to complete the intial funding of your {{ customer_data.opened_accounts.length > 1 ? 'new accounts' : 'new account' }}. If you have any questions please contact us at at (808) 844-4545 or toll free at (800) 894-5600.
            </div> 
            <div class="v-spacer-10"/>
            <div class="info-box">
              <p>
                Separate checks (no money orders) are required for each account being opened and should be made payable to yourself. {{ customer_data?.opened_accounts?.length == 1 ? 'Your check can be deposited by:' : 'Checks can be deposited by:' }} 
              </p>
            </div>
          </div>  
          <!-- this message is displayed if user is going through re-entry. No account info is returned if the user step is set to FUNDING_FAILED -->
          <div class="failed-msg" v-else>
            We were unable to verify your account. We apologize for the inconvenience.
          </div>
          <div class="check-info" v-if="!cdFound && total <= 20000" >
              <div class="form-header-sub" style="text-align: left !important;">
                Mobile Check Deposit
            </div>
            <div class="info-container">
              <img srcset="@/assets/images/mobile-icon.png 1x, @/assets/images/mobile-icon-2x.png 2x" src="@/assets/images/mobile-icon.png" title="Mobile Check"/>
              <ol>
                <li>Download and Login to the FHB Mobile app
                  <br/>
                  <div class="mobileStoreLogo">
                    <a href="https://apps.apple.com/app/id1172432893" target="_blank"><img class="appleLogo" src="@/assets/images/Apple_Store_Badge@1.5x.png" alt="apple store badge"></a>
                    <a href="https://play.google.com/store/apps/details?id=com.firsthawaiian.mobile" target="_blank"><img class="playstoreLogo" src="@/assets/images/google-play-badge@1.5x.png" alt="play store badge"></a>
                  </div>

                </li>
                <li>Login to the FHB Mobile app and go to Deposits</li>
                <li>Select your new account to deposit the check into</li>
              </ol>
            </div>
          </div>
          
          <div class="check-info">
            <div class="form-header-sub" style="text-align: left !important;">
              Mail a Check
            </div>
            <div class="info-container">
              <div class="italic">(processed within 1-2 business days<sup>*</sup> after check is received)</div>
              <img srcset="@/assets/images/mail-icon.png 1x, @/assets/images/mail-icon-2x.png 2x" src="@/assets/images/mail-icon.png" title="Mail Check"/>
              <template v-if="customer_data?.opened_accounts?.length == 1">
                <table>
                  <tr>
                    <td><img srcset="@/assets/images/mail-icon.png 1x, @/assets/images/mail-icon-2x.png 2x" src="@/assets/images/mail-icon.png" title="Mail Check"/></td>
                    <td>FHB Direct</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>PO Box 1959</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Honolulu, HI 96805</td>
                  </tr>
                </table>
              </template>
              <template v-else-if="customer_data?.opened_accounts?.length > 1">
                <img srcset="@/assets/images/mail-icon.png 1x, @/assets/images/mail-icon-2x.png 2x" src="@/assets/images/mail-icon.png" title="Mail Check"/>
                <ol>
                  <li>Include the last 4-digits of your new account number on each check (no money orders)</li>
                  <li >Mail your check to:
                    <div class="address">
                      <div>
                        FHB Direct
                      </div>
                      <div>
                        PO Box 1959
                      </div>
                      <div>
                        Honolulu, HI 96805
                      </div>
                    </div>
                  </li>
                </ol>
              </template>
            </div>
          </div> 
          <div class="hold-policy">
            <sup>*</sup>A hold may be placed on your check deposit in accordance with our check hold policy. For more information refer to the Check Hold Policy for Checking Accounts located in the Deposit Account Rules and Regulations and Other Information for Personal Accounts.
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormContainer from '@/components/FormContainer.vue'
import Splash from '@/components/Splash.vue'
import jquery from 'jquery'
import helpers from '@/app/helpers'

export default {
  name: 'ExternalFundingFailure',
  components: {
    FormContainer,
    Splash
},
  data() {
    return {
      account: '',
    }
  },
  created() {
    if (this.customer_data && this.customer_data.opened_accounts) {
      for (let acc of this.customer_data.opened_accounts) {
        if (acc.is_cd) {
          this.cdFound = true
        }
      }
    }
  },
  mounted() {
    jquery('html, body').animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions('fundnow', ['setCustomerType', 'setCustomerData', 'setCDFound' ]),

  },
  computed: {
    ...mapGetters('fundnow', ['getTotal', 'getCustomerData', 'getCDFound']),
    customer_data: {
      get() {
        return this.getCustomerData;
      },
    },
    total: {
      get() {
        return helpers.formatMoneyFromString(this.getTotal);
      },
    },
    formattedTotal: {
      get() {
        return helpers.formatMoneyToString(this.total)
      }
    },
    cdFound: {
      get() { return this.getCDFound},
      set(v) { this.setCDFound(v) }
    },
  }
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: none;
  background-color: $yellow;
  cursor: pointer;
  &:hover, :active {
    background-color: $yellowHover;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
  div {
    margin: 6px 0;
  }
}
.close-container {
  text-align: center;
  margin-top: 60px;
}
.check-info {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left !important;
  margin: 20px 0;
}
.info-container {
  img {
    margin-top: auto;
    vertical-align: top;
  }
  ol {
    max-width: 620px;
    display: inline-block;
    margin: 0 0;
  }
  li {
    margin: 5px 0;
  }
}
.mobileStoreLogo{
 margin: 10px 0;
}
.appleLogo{
  padding-right: 10px;
  height: 40px;
  width: 130px;
}
.playstoreLogo{
  height: 40px;
  width: 130px;
}
.footer-note {
  background-color: $orangeFaint;
  padding: 20px;
}
.hold-policy {
  background-color: $orangeFaint;
  padding: 15px;
}
.info-box {
  font-size: 18px;
  font-weight: 450;  
  span {
    font-weight: 700;
  }
}
.address {
  margin-left: 10px;
}
.info-container {
  table {
    margin-top: 10px;
  }
  img {
    margin-top: 10px;
    vertical-align: top;
  }
  ol {
    max-width: 620px;
    display: inline-block;
    margin: 0 0;
  }
}
</style>

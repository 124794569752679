<template>
    <div class="splash-bg">
      <div class="gradiant-bar"></div>
      <div class="splash-body"/>
      <!-- <div class="splash-diagonal"></div> -->
      
    </div>
  
  </template>
  
  <script>
  export default {
    name: 'FullSplashBackground',
    props: {
      type: String,
      fname: String
    },
    components : {
  
    },
    // methods: {
    //   loadPromoCodePage() {
    //     this.$router.push({ name: 'Offer Code'})
    //   },
    // }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "./src/scss/_variables.scss";
  @import "./src/scss/_mixins.scss";
  
  .splash-bg {
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .gradiant-bar {
    height: 20px;
    background: linear-gradient(180deg, #F1F1F1 0%, rgba(255, 255, 255, 0) 100%);
    mix-blend-mode: multiply;
  }
  .splash-body {
    height: 400px;
    // background-color: $orangeFocus;
    // background-image: url('../assets/images/orange@1x.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    // background-size: 1200px;
    margin-top: -20px;
    // padding-bottom: 10.5em;
    position: relative;
    // padding-bottom: 100px;
  }
  @media (min-width: 768px) {
    .splash-body {
      padding-bottom: 140px;
    }
  }
  .splash-diagonal {
    display: block;
    position: absolute;
    z-index: 3; 
    background-image: url('../assets/images/white-bottom-left-corner.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 100% 60px;
    bottom: 0px;
    width: 100%;
    height: 60px;
  }
  @media (min-width: 768px) {
    .splash-diagonal {
      background-size: 100% 100px;
      height: 100px;
    }
  }
  .splash-title {
    padding-top: 75px;
  }
  .splash-callout {
    font-style: normal;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1.5;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-feature-settings: 'cpsp' on;
    color: $black;
    padding-left: 10px;
  }
  h1 {
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
    text-align: left;
    color: $black;
    text-align: left;
    margin: 0;
  
    @include media-breakpoint-up(lg) {
      font-size: 2.75rem;
    }
  }
  h2 {
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
    text-align: left;
    color: $black;
    margin: 0.1em 0 0;
  
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }
  .grid-container {
    text-align: left;
    margin-top: 2em;
    position: relative;
    z-index: 20;
    
    @include media-breakpoint-up(md) {
      align-items: center;
      display: grid;
      grid-column-gap: 1em;
      grid-template-columns: auto 420px;
    }
  }
  .duration-msg {
    display: flex;
    align-items: flex-start;
  }
  .duration-msg .splash-callout > span {
    display: inline;
  
    @include media-breakpoint-only(md) {
      display: block;
    }
  }
  .capsule-callout {
    box-sizing: border-box;
    display: inline-block;
    height: auto;
    width: auto;
    text-align: center;
  
    @include media-breakpoint-down(md) {
      margin-top: 1em;
    }
  }
  .capsule-msg {
    align-items: center;
    background: $white;
    border: 1px solid rgba(247, 147, 76, 0.16);
     border-radius: 35px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: center;
    min-height: 60px;
    padding: 0.5em 1.5em;
  
    &:hover,
    &:focus,
    &:active {
      background-color: fade-out($yellowHover, 0.5);
      cursor: pointer;
      transition: background-color 300ms ease,300ms ease opacity;
  
      .capsule-link {
        color: $black;
      }
    }
  }
  .capsule-text {
    font-style: normal;
    font-weight: normal;
    font-size: 0.95rem;
    line-height: 150%;
    color: $black;
  }
  .capsule-link {
    color: $red;
    font-size: 0.9rem;
    font-weight: 700;
  }
  .footer-container {
    height: 100px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 2;
  }
  .classout-icon {
    height: auto;
    width: 18px;
  }
  </style>
  
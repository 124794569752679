<template>
  <header class="header-bg">
    <div class="header-container container">
      <img
        srcset="
          @/assets/images/fdic-logo-white.png    1x,
          @/assets/images/fdic-logo-white@2x.png 2x
        "
        src="@/assets/images/fdic-logo-white@2x.png"
        alt="First Hawaiian Bank home logo"
        title="FHB home"
      />
      <span id="fdic-text"
        >FDIC-Insured—Backed by the full faith and credit of the U.S.
        Government</span
      >
    </div>
  </header>
</template>

<script>
export default {
  name: "FDICHeader",
  props: {
    type: String,
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.header-bg {
  background: #474747;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  #fdic-text {
    font-family: "Source Sans 3", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: italic;
    color: #ffffff;
    font-size: 12.8px;
  }
}
.header-container {
  align-items: center;
  text-align: left;
  display: flex;
  gap: 1em;
  height: 55px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.3em 0 0.5em;
  color: $white;
  @include media-breakpoint-up(md) {
    height: 80px;
    gap: 0.5em;
  }
}
.header-container img {
  display: block;
}
.title {
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  color: $black;
  @include media-breakpoint-down(md) {
    display: none;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.15rem;
  }
}
sup {
  font-size: 0.6rem;
  line-height: 1.5;
  white-space: nowrap;
}
</style>

import InternalTransfer from '@/views/Internal/InternalTransfer'
import InternalTransferInitated from '@/views/Internal/InternalTransferInitiated'
import InternalTransferUnsuccessful from '@/views/ErrorPages/InternalTransferUnsuccessful'
import InternalTransferPartialFailure from '@/views/ErrorPages/InternalTransferPartialFailure.vue'
import InternalTransferFullFailure from '@/views/ErrorPages/InternalTransferFullFailure.vue'
import auth from '../auth_routes'
import store from '@/store'

const jwtISS = 'FUNDING-ISS'

const internalRoutes = {
    getToken () {
        return store.getters['fundnow/getJwtToken']
    },
    getRedirect () {
        return '/'
    },
    
    loadRoutes(routes) {
        routes.push(
            {
                path: '/internal/transfer-from-account',
                name: 'Transfer from FHB Account',
                component: InternalTransfer,
                beforeEnter: (to, from, next) => {
                    auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
                }
          
            },
            {
                path: '/internal/transfer-initiated',
                name: 'Internal Transfer Initiated',
                component: InternalTransferInitated,
                beforeEnter: (to, from, next) => {
                    auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
                }
            },
            {
                path: '/internal/transfer-unsuccessful',
                name: 'Internal Transfer Unsuccessful',
                component: InternalTransferUnsuccessful,
                beforeEnter: (to, from, next) => {
                    auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
                }
            },
            {
                path: '/internal/partial-failure',
                name: 'Internal Transfer Partial Failure',
                component: InternalTransferPartialFailure,
                beforeEnter: (to, from, next) => {
                    auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
                }
            },
            {
                path: '/internal/transfer-failure',
                name: 'Internal Transfer Full Failure',
                component: InternalTransferFullFailure,
                beforeEnter: (to, from, next) => {
                    auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
                }
            },

        )
        return routes
    }
}

export default internalRoutes
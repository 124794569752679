// import FundByCheck from '@/views/Check/FundByCheck'
import Login from '@/views/Enrollment/Login.vue'
import Enrollment from "@/views/Enrollment/Enrollment.vue"
// import auth from '../auth_routes'
import store from '@/store'

// const jwtISS = 'FUNDING-ISS'

const enrollmentRoutes = {
    getToken () {
        return store.getters['fundnow/getJwtToken']
    },
    getRedirect () {
        return '/'
    },
    loadRoutes(routes) {
        routes.push(
            {
                path: '/enroll',
                name: 'Enroll new customer',
                component: Enrollment,
                // beforeEnter: (to, from, next) => {
                //     auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
                // }
            },
            {
                path: '/login',
                name: 'Login After Enrollment',
                component: Login,
                // beforeEnter: (to, from, next) => {
                //     auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
                // }
            }
        )
        return routes
    }
}

export default enrollmentRoutes
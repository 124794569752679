import axios from "axios"

const FUNDING_BASE_URL = process.env.VUE_APP_API_URL    

export default {
    getJwtRequestData(token) {
        return {
            headers: {
            Authorization: 'Bearer ' + token
            }
        }
    },
    async getFundingAccounts(redirect_id) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${redirect_id}`
        return axios.get(endpoint).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },
    async postInternalFunding(app_id, token, payload) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${app_id}/internal/fund`
        const header = this.getJwtRequestData(token)
        return axios.post(endpoint, payload, header).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },

    async postFundingInit(app_id, token, payload) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${app_id}/init`
        const header = this.getJwtRequestData(token)
        return axios.post(endpoint, payload, header).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },

    async returnToAppMFAInit(funding_id, payload) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${funding_id}/auth/mfa/init`

        return axios.post(endpoint, payload, null).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },
    async postTrialDepositInit(app_id, token) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${app_id}/external/trial-deposits/init`
        const header = this.getJwtRequestData(token)
        return axios.post(endpoint, null, header).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },

    async returnToAppMFASendCode(funding_id, payload) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${funding_id}/auth/mfa`

        return axios.post(endpoint, payload, null).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },
    async postVerifyTrialDeposit(app_id, token, payload) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${app_id}/external/trial-deposits/verify`
        const header = this.getJwtRequestData(token)
        return axios.post(endpoint, payload, header).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },

    async returnToAppMFAVerify(funding_id, code) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${funding_id}/auth/mfa/verify`

        const payload = {
            code: code
        }

        return axios.post(endpoint, payload, null).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },
    async postRealtimeData(funding_id, token, payload) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${funding_id}/external/realtime`
        const header = this.getJwtRequestData(token)
        return axios.post(endpoint, payload, header).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },
    async postExternalFunding(funding_id, token) {
        const endpoint = `${FUNDING_BASE_URL}/funding/${funding_id}/external/fund`
        const header = this.getJwtRequestData(token)
        return axios.post(endpoint,'', header).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    },
    async postRefreshToken(token) {
        const endpoint = `${FUNDING_BASE_URL}/token/refresh`
        const header = this.getJwtRequestData(token)
        return axios.post(endpoint,'', header).then(response => {
            return response
        }).catch(err => {
            return err.response
        })
    }
}
<template>
    <header class="header-bg">
      <div class="header-container container">
        <a href="https://www.fhb.com/">
          <img class="header-logo" srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x" src="@/assets/images/FHBColorLogo-2x.png" alt="First Hawaiian Bank home logo" title="FHB home" />
        </a>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: 'FHB Header',
    props: {
      type: String
    },
    components : {
  
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "./src/scss/_variables.scss";
  @import "./src/scss/_mixins.scss";
  
  .header-bg {
    background: $white;
    box-shadow: 0px 0px 24px -3px #00000026;
;
  }
  .header-container {
    align-items: center;
    text-align: left;
    display: flex;
    gap: 1em;
    height: 55px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.3em 0 0.5em;
    @include media-breakpoint-up(md) {
      height: 80px;
      gap: 2em;
    }
  }
  .header-container img {
    display: block;
  
    /*@include media-breakpoint-up(md) {
      margin-bottom: 0.15rem;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0.3rem;
    }*/
  }
  .title {
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    color: $black;
    @include media-breakpoint-down(md) {
      display: none;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.15rem;
    }
  }
  sup {
    font-size: 0.6rem;
    line-height: 1.5;
    white-space: nowrap;
  }
  </style>
  